import { Center, Flex as ChakraFlex } from "@chakra-ui/react";
import { Box, Flex, Heading, Select } from "@ridepanda/bamboo-ui";
import { useState } from "react";
import { usePortalActivityMetricsQuery } from "../../api/types-and-hooks";
import { BambooCard } from "../../components/BambooCard";
import { HomeLoader } from "../../components/Loaders";
import { useApp } from "../../contexts/AppContext";
import styles from "./Insights.module.css";
import { ActivityItem } from "./components/ActivityItem";
import { OrdersXYChart } from "./components/OrdersXYChart";
import { SessionsXYChart } from "./components/SessionsXYChart";

export function PortalActivity() {
  const [selectedLocation, setSelectedLocation] = useState("all");
  const [selectedDateRange, setSelectedDateRange] = useState("last-30");
  const { organization } = useApp();
  const locations = organization?.locations || [];

  const daysPeriodInMs =
    Number(selectedDateRange.split("-")[1]) * 24 * 60 * 60 * 1000;

  const startDate = new Date(new Date().getTime() - daysPeriodInMs)
    .toISOString()
    .substring(0, 10);

  const endDate = new Date().toISOString().substring(0, 10);

  const { data, error, loading } = usePortalActivityMetricsQuery({
    variables: {
      locationId: selectedLocation === "all" ? null : selectedLocation,
      startDate,
      endDate,
    },
  });

  const { currentStats, previousStats } = data?.portalActivityMetrics || {};
  const dailyStats = currentStats?.details || [];

  return (
    <>
      <Flex mt="48" justify="between" align="center">
        <Heading size="24" weight="bold">
          Portal Activity
        </Heading>

        <Flex gap="8">
          <Select
            size="sm"
            value={selectedLocation}
            onValueChange={setSelectedLocation}
            options={[
              {
                label: "All locations",
                value: "all",
              },
              ...locations.map((location) => ({
                label: location.name,
                value: location.id,
              })),
            ]}
          />
          <Select
            size="sm"
            value={selectedDateRange}
            onValueChange={setSelectedDateRange}
            options={[
              {
                label: "Last 30 days",
                value: "last-30",
              },
              {
                label: "Last 60 days",
                value: "last-60",
              },
              {
                label: "Last 90 days",
                value: "last-90",
              },
            ]}
          />
        </Flex>
      </Flex>

      <BambooCard mt="16">
        <Box className={styles.PortalActivity}>
          <Flex className={styles.PortalActivity_List} direction="col">
            <ActivityItem
              title="Orders"
              value={currentStats?.ordersCount}
              previousValue={previousStats?.ordersCount}
            />
          </Flex>
          <ChakraFlex
            width="full"
            minW={0}
            minH={260}
            position="relative"
            pb={5}
          >
            {loading && <HomeLoader w="full" />}
            {!loading && dailyStats.length > 0 && (
              <OrdersXYChart dataset={dailyStats} />
            )}
            {!loading && dailyStats.length === 0 && (
              <Center minH={265} w="full">
                Not enough data to show
              </Center>
            )}
            {error && (
              <Center minH={265} w="full">
                There was an error trying to load the data
              </Center>
            )}
          </ChakraFlex>
        </Box>
      </BambooCard>

      <BambooCard mt="16">
        <Box className={styles.PortalActivity}>
          <Flex className={styles.PortalActivity_List} direction="col">
            <ActivityItem
              title="Visitors"
              value={currentStats?.uniqueVisitorsCount}
              previousValue={previousStats?.uniqueVisitorsCount}
            />
            <ActivityItem
              title="Sessions"
              value={currentStats?.sessionsCount}
              previousValue={previousStats?.sessionsCount}
            />
          </Flex>
          <ChakraFlex
            width="full"
            minW={0}
            minH={260}
            position="relative"
            pb={5}
          >
            {loading && <HomeLoader w="full" />}
            {!loading && dailyStats.length > 0 && (
              <SessionsXYChart dataset={dailyStats} />
            )}
            {!loading && dailyStats.length === 0 && (
              <Center minH={265} w="full">
                Not enough data to show
              </Center>
            )}
            {error && (
              <Center minH={265} w="full">
                There was an error trying to load the data
              </Center>
            )}
          </ChakraFlex>
        </Box>
      </BambooCard>
    </>
  );
}

export default PortalActivity;
