import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  ColorCode: any;
  Decimal: any;
  ISO8601Date: any;
  ISO8601DateTime: any;
  JSON: any;
  Url: any;
};

export type Address = {
  city: Scalars['String'];
  countryCode: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  stateCode: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type AddressInput = {
  city: Scalars['String'];
  countryCode: Scalars['String'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  stateCode: Scalars['String'];
};

export type AnalyticsActiveRidersDetail = {
  date: Scalars['ISO8601Date'];
  ebikeCount: Scalars['Int'];
  escooterCount: Scalars['Int'];
  pedalBikeCount: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type AnalyticsActiveRidersMetrics = {
  currentStats: AnalyticsActiveRidersStats;
  projectedStats?: Maybe<AnalyticsActiveRidersStats>;
};

export type AnalyticsActiveRidersStats = {
  carTripsAvoided: Scalars['Int'];
  details: Array<AnalyticsActiveRidersDetail>;
  totalCo2OffsetKg: Scalars['Int'];
  totalMiles: Scalars['Int'];
  treesPlantedEquivalency: Scalars['Float'];
};

export type AnalyticsActivityDetail = {
  date: Scalars['ISO8601Date'];
  ordersCount: Scalars['Int'];
  sessionsCount: Scalars['Int'];
  uniqueVisitorsCount: Scalars['Int'];
};

export type AnalyticsActivityMetrics = {
  currentStats: AnalyticsActivityStats;
  previousStats: AnalyticsActivityStats;
};

export type AnalyticsActivityStats = {
  date: Scalars['ISO8601Date'];
  details: Array<AnalyticsActivityDetail>;
  ordersCount: Scalars['Int'];
  sessionsCount: Scalars['Int'];
  uniqueVisitorsCount: Scalars['Int'];
};

export type Asset = {
  allowBuyout: Scalars['Boolean'];
  assetSubscriptions: Array<AssetSubscription>;
  buyoutAvailableAt?: Maybe<Scalars['ISO8601Date']>;
  buyoutEligible: Scalars['Boolean'];
  buyoutPriceCents?: Maybe<Scalars['Int']>;
  checkedInAt?: Maybe<Scalars['ISO8601DateTime']>;
  cogsCents?: Maybe<Scalars['Int']>;
  comments?: Maybe<Array<Note>>;
  createdAt: Scalars['ISO8601DateTime'];
  customerInvoices?: Maybe<Array<CustomerInvoice>>;
  events?: Maybe<Array<Event>>;
  frameKey?: Maybe<Scalars['String']>;
  hub: Hub;
  id: Scalars['ID'];
  lifetimeEarningCents: Scalars['Int'];
  lockKey?: Maybe<Scalars['String']>;
  msrpAtPurchaseCents: Scalars['Int'];
  nextStates?: Maybe<Array<AssetState>>;
  order?: Maybe<Order>;
  orders?: Maybe<Array<Order>>;
  pledged: Scalars['Boolean'];
  pledgedAt?: Maybe<Scalars['ISO8601DateTime']>;
  purchaseOrder?: Maybe<PurchaseOrder>;
  purchasedAt?: Maybe<Scalars['ISO8601DateTime']>;
  serialNumber?: Maybe<Scalars['String']>;
  shippedAt?: Maybe<Scalars['ISO8601DateTime']>;
  soldAt?: Maybe<Scalars['ISO8601DateTime']>;
  state: AssetState;
  subscription?: Maybe<Subscription>;
  subscriptionRevenueCents: Scalars['Int'];
  subscriptions?: Maybe<Array<Subscription>>;
  theftReport?: Maybe<TheftReport>;
  totalRevenueCents: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
  variant: Variant;
  wholesalePriceCents: Scalars['Int'];
};

export type AssetCheckInInput = {
  /** The ID of the asset to check in */
  id: Scalars['ID'];
  /** The serial number of the asset */
  serialNumber: Scalars['String'];
};

/** The connection type for Asset. */
export type AssetConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AssetEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Asset>>>;
  pageInfo: PageInfo;
};

export type AssetDetailsInput = {
  /** Whether the asset is buyout eligible */
  buyoutEligible?: InputMaybe<Scalars['Boolean']>;
  /** The frame key of the asset */
  frameKey?: InputMaybe<Scalars['String']>;
  /** The lock key of the asset */
  lockKey?: InputMaybe<Scalars['String']>;
  /** The serial number of the asset */
  serialNumber: Scalars['String'];
};

/** An edge in a connection. */
export type AssetEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Asset>;
};

export type AssetInput = {
  /** The hub ID to associate the asset with.If nil, the hub will be inferred from the purchase order */
  hubId?: InputMaybe<Scalars['ID']>;
  /** The ID of the asset to update */
  id?: InputMaybe<Scalars['ID']>;
  /** The manufacturer's suggested retail price of the asset, in cents */
  msrpAtPurchaseCents?: InputMaybe<Scalars['Int']>;
  /** The purchase order ID to associate the asset with. */
  purchaseOrderId?: InputMaybe<Scalars['ID']>;
  /** The serial number of the asset */
  serialNumber?: InputMaybe<Scalars['String']>;
  /** The variant ID to associate the asset with */
  variantId: Scalars['ID'];
  /** The wholesale price of the asset, in cents */
  wholesalePriceCents: Scalars['Int'];
};

export enum AssetSortColumn {
  customer = 'customer',
  SerialNumber = 'serialNumber',
  StateWeight = 'stateWeight',
  vehicle = 'vehicle'
}

export enum AssetState {
  /** active asset state */
  Active = 'ACTIVE',
  /** decommissioned asset state */
  Decommissioned = 'DECOMMISSIONED',
  /** maintenance_hold asset state */
  MaintenanceHold = 'MAINTENANCE_HOLD',
  /** open_po asset state */
  OpenPo = 'OPEN_PO',
  /** received asset state */
  Received = 'RECEIVED',
  /** returned asset state */
  Returned = 'RETURNED',
  /** shipped asset state */
  Shipped = 'SHIPPED',
  /** sold asset state */
  Sold = 'SOLD',
  /** stolen asset state */
  Stolen = 'STOLEN',
  /** test_ride asset state */
  TestRide = 'TEST_RIDE'
}

export type AssetSubscription = {
  asset: Asset;
  endedAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  startedAt: Scalars['ISO8601DateTime'];
  subscription: Subscription;
};

export type AssetsByVariant = {
  assets: Array<Asset>;
  color: Scalars['String'];
  size: Scalars['String'];
};

export type AssetsForPoTab = {
  assetsByVariant: Array<AssetsByVariant>;
  brand: Scalars['String'];
  model: Scalars['String'];
  totalCount: Scalars['Int'];
};

export type BatchOperationResult = {
  failed: Array<ResourceError>;
  succeeded: Array<Scalars['ID']>;
};

export type Brand = {
  id: Scalars['ID'];
  logo?: Maybe<Image>;
  name: Scalars['String'];
};

export type BrandInput = {
  /** The logo of the brand */
  logo?: InputMaybe<MediaInput>;
  /** The name of the brand */
  name?: InputMaybe<Scalars['String']>;
};

export type Card = {
  brand: Scalars['String'];
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  last4: Scalars['String'];
};

export type CardUpdateSessionCreatePayload = {
  clientSecret: Scalars['String'];
};

/** Autogenerated return type of CheckoutDataCreate. */
export type CheckoutDataCreatePayload = {
  /** The spoke checkout URL */
  checkoutUrl: Scalars['Url'];
};

export type CheckoutSessionCreatePayload = {
  buyoutPriceCents: Scalars['Int'];
  clientSecret: Scalars['String'];
  clientSecretType: Scalars['String'];
  discount?: Maybe<LineItem>;
  /** @deprecated not used */
  subtotal: LineItem;
  taxAmount?: Maybe<LineItem>;
  /** @deprecated use `tax_amount` */
  taxRate?: Maybe<LineItem>;
  termLength: Scalars['Int'];
  total: LineItem;
};

export enum CurrencyCode {
  /** United States Dollars (USD). */
  Usd = 'USD'
}

export type Customer = {
  activationCode: Scalars['String'];
  address?: Maybe<Address>;
  addresses?: Maybe<Array<Address>>;
  card?: Maybe<Card>;
  currentSubscription?: Maybe<Subscription>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  frontContactId: Scalars['String'];
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  lastOrder?: Maybe<Order>;
  lightspeedCustomerId?: Maybe<Scalars['String']>;
  location: Location;
  organization: Organization;
  phone?: Maybe<Scalars['String']>;
  spoke?: Maybe<Spoke>;
  stripeCustomerId: Scalars['String'];
};

/** The connection type for Customer. */
export type CustomerConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CustomerEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Customer>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CustomerEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Customer>;
};

export type CustomerInvoice = {
  address?: Maybe<Address>;
  asset?: Maybe<Asset>;
  createdAt: Scalars['ISO8601DateTime'];
  currencyCode: Scalars['String'];
  customer: Customer;
  id: Scalars['ID'];
  number: Scalars['String'];
  organization: Organization;
  paidAt?: Maybe<Scalars['ISO8601DateTime']>;
  state: CustomerInvoiceState;
  stripeInvoiceId: Scalars['String'];
  stripePriceId: Scalars['String'];
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  swapped: Scalars['Boolean'];
  totalCents?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum CustomerInvoiceState {
  /** canceled customer invoice state */
  Canceled = 'CANCELED',
  /** paid customer invoice state */
  Paid = 'PAID',
  /** pending customer invoice state */
  Pending = 'PENDING',
  /** refunded customer invoice state */
  Refunded = 'REFUNDED'
}

export enum CustomerSortColumn {
  CreatedAt = 'createdAt',
  email = 'email',
  FirstName = 'firstName',
  HubName = 'hubName',
  LastName = 'lastName',
  LocationName = 'locationName',
  OrganizationName = 'organizationName',
  SpokeName = 'spokeName'
}

export type CustomerUpdateInput = {
  /** Email of the customer */
  email?: InputMaybe<Scalars['String']>;
  /** First name of the customer */
  firstName?: InputMaybe<Scalars['String']>;
  /** Last name of the customer */
  lastName?: InputMaybe<Scalars['String']>;
  /** Phone of the customer */
  phone?: InputMaybe<Scalars['String']>;
};

export type CustomerValidationResult = {
  customer?: Maybe<Customer>;
  error?: Maybe<Scalars['String']>;
  valid: Scalars['Boolean'];
};

export type Event = {
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum ExportReportStatus {
  /** done export state */
  Done = 'DONE',
  /** failed export state */
  Failed = 'FAILED',
  /** in_progress export state */
  InProgress = 'IN_PROGRESS',
  /** queued export state */
  Queued = 'QUEUED'
}

export type Faq = {
  answer: Scalars['String'];
  id: Scalars['ID'];
  question: Scalars['String'];
};

export type FaqInput = {
  /** Answer used in body */
  answer: Scalars['String'];
  /** FAQ ID */
  id?: InputMaybe<Scalars['ID']>;
  /** Question used in title */
  question: Scalars['String'];
};

export type FileUploadUrlPayload = {
  /** Indicates whether this file was already uploaded */
  existingFile: Scalars['Boolean'];
  /** The HTTP method to use to upload */
  httpMethod: Scalars['String'];
  /** The URL to view the file when uploaded */
  publicUrl: Scalars['Url'];
  /** The URL to use to upload */
  uploadUrl: Scalars['Url'];
};

/** A float range */
export type FloatRange = {
  isSingleValue: Scalars['Boolean'];
  max?: Maybe<Scalars['Float']>;
  min: Scalars['Float'];
};

/** A float range */
export type FloatRangeInput = {
  isSingleValue: Scalars['Boolean'];
  max?: InputMaybe<Scalars['Float']>;
  min: Scalars['Float'];
};

export type Hub = {
  address?: Maybe<Address>;
  /** @deprecated Use address instead */
  addressObj?: Maybe<Address>;
  businessHours: Scalars['String'];
  directShipEnabled: Scalars['Boolean'];
  email: Scalars['String'];
  fullAddress?: Maybe<Scalars['String']>;
  googleMapsUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locations: Array<Location>;
  locationsPageCopy: Scalars['String'];
  maxTimeToFulfill: Scalars['Int'];
  minTimeToFulfill: Scalars['Int'];
  name: Scalars['String'];
  pandacareUrl?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  readyForPickupUrl?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  stripeTaxRateId?: Maybe<Scalars['String']>;
  taxRatePercentage?: Maybe<Scalars['Float']>;
  testRideUrl: Scalars['String'];
};

export type HubInput = {
  address?: InputMaybe<AddressInput>;
  /** The business hours of the hub */
  businessHours: Scalars['String'];
  /** Whether direct ship is enabled for the hub */
  directShipEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The contact email of the hub */
  email: Scalars['String'];
  /** The Google Maps URL for the hub */
  googleMapsUrl: Scalars['Url'];
  /** The location IDs to associate the hub with */
  locationIds: Array<Scalars['ID']>;
  /** The description for the hub in Portal's locations page */
  locationsPageCopy: Scalars['String'];
  /** Maximum time to fulfill an order */
  maxTimeToFulfill?: InputMaybe<Scalars['Int']>;
  /** Minimum time to fulfill an order */
  minTimeToFulfill?: InputMaybe<Scalars['Int']>;
  /** The name of the hub */
  name: Scalars['String'];
  /** The PandaCare diagnostics URL for the hub */
  pandacareUrl?: InputMaybe<Scalars['Url']>;
  /** The contact phone of the hub */
  phone: Scalars['String'];
  /** The Ready for Pickup Calendly URL for the hub */
  readyForPickupUrl: Scalars['Url'];
  /** The Stripe Tax Rate ID for the hub */
  stripeTaxRateId?: InputMaybe<Scalars['String']>;
  /** The Test Ride URL for the hub */
  testRideUrl: Scalars['Url'];
};

export type HubVariant = {
  allowBackorders: Scalars['Boolean'];
  hub: Hub;
  id: Scalars['ID'];
  variant: Variant;
  variantCount: Scalars['Int'];
};

export type HubVariantInput = {
  /** Allow Backorders */
  allowBackorders: Scalars['Boolean'];
  /** Hub ID */
  hubId: Scalars['ID'];
  /** Variant count */
  variantCount: Scalars['Int'];
  /** Variant ID */
  variantId: Scalars['ID'];
};

export type HubVehicleCategoriesInput = {
  /** Hub ID */
  hubId: Scalars['ID'];
  /** List of Vehicle Categories of the Hub */
  vehicleCategories: Array<VehicleCategory>;
};

export type Image = {
  id: Scalars['ID'];
  url: Scalars['Url'];
};

export type Invoice = {
  amountPaid: Money;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  number: Scalars['String'];
  pdfUrl: Scalars['Url'];
  status: InvoiceStatus;
};

export enum InvoiceStatus {
  /** The starting status for all invoices */
  draft = 'draft',
  /** The invoice has been finalized, and is awaiting customer payment */
  open = 'open',
  /** This invoice was paid */
  paid = 'paid',
  /** This invoice was a mistake, and must be canceled */
  uncollectible = 'uncollectible',
  /** The customer is unlikely to pay this invoice */
  void = 'void'
}

/** Return type for `joinWaitlist` mutation */
export type JoinWaitlistPayload = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
  organizationId?: Maybe<Scalars['ID']>;
};

export type LeasePrice = {
  buyoutPriceCents?: Maybe<Scalars['String']>;
  leaseMonthlyPriceCents?: Maybe<Scalars['String']>;
  totalSavingsCents?: Maybe<Scalars['String']>;
};

export type LineItem = {
  /** Decimal money amount. */
  amount: Scalars['Decimal'];
  /** Currency of the money. */
  currencyCode: CurrencyCode;
  /** The receipt display name */
  displayName: Scalars['String'];
};

export type Location = {
  editable: Scalars['Boolean'];
  hub?: Maybe<Hub>;
  id: Scalars['ID'];
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
};

export type LogHistory = {
  category: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  createdBy: Scalars['String'];
  modifications: Scalars['JSON'];
};

export type Media = {
  id: Scalars['ID'];
  url: Scalars['Url'];
};

export type MediaAttachment = {
  media: Media;
  uploadedBy?: Maybe<Scalars['String']>;
};

export type MediaInput = {
  /** Media id. Media must exist if id is set */
  id?: InputMaybe<Scalars['ID']>;
  /** Media url. Creates a file if none found with given url */
  url?: InputMaybe<Scalars['Url']>;
};

export type Money = {
  /** Decimal money amount. */
  amount: Scalars['Decimal'];
  /** Currency of the money. */
  currencyCode: CurrencyCode;
};

export type MoneyInput = {
  /** Decimal money amount. */
  amount: Scalars['Decimal'];
  /** Currency of the money. */
  currencyCode?: InputMaybe<CurrencyCode>;
};

export type Mutation = {
  /** Updates the asset state to `active`. */
  assetActivate?: Maybe<Asset>;
  /** Updates the asset state to `received`. Updates PO to `check in` for first assetand `closed` if all assets are received */
  assetCheckIn?: Maybe<Asset>;
  /** Updates the asset state to `decommissioned` */
  assetDecommission?: Maybe<Asset>;
  /** Updates the asset state to `maintenance_hold` */
  assetMaintenanceHold?: Maybe<Asset>;
  /** Updates the asset state to `stolen`.It also updates the subscription state to `canceled` if the asset is currently leased */
  assetReportStolen?: Maybe<Asset>;
  /** Updates the asset state to `returned`. */
  assetReturn?: Maybe<Asset>;
  /** Updates the asset state to `sold`. */
  assetSold?: Maybe<Asset>;
  /** Updates the asset state to `test_ride`. */
  assetTestRide?: Maybe<Asset>;
  /** Updates the asset attributes */
  assetUpdate?: Maybe<Asset>;
  /** Updates the asset's hub */
  assetUpdateHub?: Maybe<Asset>;
  /** Creates a job to export assets */
  assetsExport?: Maybe<ReportExport>;
  assetsMarkCheckedIn?: Maybe<BatchOperationResult>;
  assetsMarkShipped?: Maybe<BatchOperationResult>;
  brandCreateOrUpdate?: Maybe<Brand>;
  cardUpdateSessionCreate?: Maybe<CardUpdateSessionCreatePayload>;
  checkoutSessionCreate?: Maybe<CheckoutSessionCreatePayload>;
  customerUpdate?: Maybe<Customer>;
  fileUploadUrlCreate?: Maybe<FileUploadUrlPayload>;
  forestUserUpdate?: Maybe<User>;
  hubCreateOrUpdate?: Maybe<Hub>;
  joinWaitlist?: Maybe<JoinWaitlistPayload>;
  noteCreate?: Maybe<Note>;
  orderCancel?: Maybe<Order>;
  orderCreate?: Maybe<OrderValidationResult>;
  orderFulfill?: Maybe<Order>;
  orderOnHold?: Maybe<Order>;
  orderOpen?: Maybe<Order>;
  orderReady?: Maybe<Order>;
  orderRemoveAsset?: Maybe<Order>;
  orderVariantUpdate?: Maybe<Order>;
  /** Creates a job to export orders */
  ordersExport?: Maybe<ReportExport>;
  organizationCreateOrUpdate?: Maybe<Organization>;
  /** Creates a job to export paid invoices from stripe */
  paidInvoicesExport?: Maybe<ReportExport>;
  /** Creates a job to export purchase order details */
  purchaseOrderAssetsExport?: Maybe<ReportExport>;
  purchaseOrderCreateOrUpdate?: Maybe<PurchaseOrder>;
  purchaseOrderDelete?: Maybe<TransactionResult>;
  purchaseOrderShip?: Maybe<PurchaseOrder>;
  purchaseOrderSubmit?: Maybe<PurchaseOrder>;
  /** Creates a job to export revenue by state */
  revenueByStateExport?: Maybe<ReportExport>;
  spokeCheckoutDataCreate?: Maybe<CheckoutDataCreatePayload>;
  spokeCheckoutSessionCreate?: Maybe<CheckoutSessionCreatePayload>;
  spokeCreateOrUpdate?: Maybe<Spoke>;
  spokeCustomerCreate?: Maybe<SpokeCustomerCreateResult>;
  spokeInvoiceTransitionState?: Maybe<SpokeInvoice>;
  spokeInvoiceUpdate?: Maybe<SpokeInvoice>;
  spokeUpdateBrands?: Maybe<Spoke>;
  spokeUpdateLocations?: Maybe<Spoke>;
  spokeUpdateVehicleCategories?: Maybe<Spoke>;
  spokeVehicleCategoryCreateOrUpdate?: Maybe<SpokeVehicleCategory>;
  subscriptionBuyout?: Maybe<Subscription>;
  subscriptionCancel?: Maybe<Subscription>;
  subscriptionCustomerOffboard?: Maybe<Subscription>;
  subscriptionUpdate?: Maybe<Subscription>;
  subscriptionVariantUpdate?: Maybe<Variant>;
  /** Creates a job to export subscriptions */
  subscriptionsExport?: Maybe<ReportExport>;
  theftReportCreateOrUpdate?: Maybe<TheftReport>;
  variantHubInventoryUpdate?: Maybe<Array<HubVariant>>;
  /** Creates a job to export Variant Stats */
  variantStatsExport?: Maybe<ReportExport>;
  vehicleCreateOrUpdate?: Maybe<Vehicle>;
  /** Passing empty `id` will create a new vendor, otherwise it will update it */
  vendorCreateOrUpdate?: Maybe<Vendor>;
};


export type MutationAssetActivateArgs = {
  assetId: Scalars['ID'];
  maintenanceNotes?: InputMaybe<Scalars['String']>;
};


export type MutationAssetCheckInArgs = {
  id: Scalars['ID'];
  serialNumber?: InputMaybe<Scalars['String']>;
};


export type MutationAssetDecommissionArgs = {
  assetId: Scalars['ID'];
  reason: Scalars['String'];
};


export type MutationAssetMaintenanceHoldArgs = {
  assetId: Scalars['ID'];
  maintenanceDescription?: InputMaybe<Scalars['String']>;
  serialNumber?: InputMaybe<Scalars['String']>;
};


export type MutationAssetReportStolenArgs = {
  assetId: Scalars['ID'];
};


export type MutationAssetReturnArgs = {
  assetId: Scalars['ID'];
  maintenanceNotes?: InputMaybe<Scalars['String']>;
};


export type MutationAssetSoldArgs = {
  assetId: Scalars['ID'];
  buyoutPriceCents: Scalars['Int'];
  customerEmail?: InputMaybe<Scalars['String']>;
  soldAt: Scalars['String'];
};


export type MutationAssetTestRideArgs = {
  assetId: Scalars['ID'];
};


export type MutationAssetUpdateArgs = {
  assetId: Scalars['ID'];
  attributes: AssetDetailsInput;
};


export type MutationAssetUpdateHubArgs = {
  assetId: Scalars['ID'];
  hubId: Scalars['ID'];
};


export type MutationAssetsExportArgs = {
  hubId?: InputMaybe<Scalars['ID']>;
  includeActiveSubscription?: InputMaybe<Scalars['Boolean']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<AssetSortColumn>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type MutationAssetsMarkCheckedInArgs = {
  assets: Array<AssetCheckInInput>;
};


export type MutationAssetsMarkShippedArgs = {
  ids: Array<Scalars['ID']>;
  timestamp?: InputMaybe<Scalars['ISO8601DateTime']>;
};


export type MutationBrandCreateOrUpdateArgs = {
  attributes: BrandInput;
  brandId?: InputMaybe<Scalars['ID']>;
};


export type MutationCheckoutSessionCreateArgs = {
  email: Scalars['String'];
  locationId: Scalars['ID'];
  organizationSlug: Scalars['String'];
  variantId: Scalars['ID'];
};


export type MutationCustomerUpdateArgs = {
  attributes: CustomerUpdateInput;
  customerId: Scalars['ID'];
};


export type MutationFileUploadUrlCreateArgs = {
  fileSize: Scalars['BigInt'];
  mimeType: Scalars['String'];
  prefix: Scalars['String'];
};


export type MutationForestUserUpdateArgs = {
  disabled?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  hubId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationHubCreateOrUpdateArgs = {
  attributes: HubInput;
  hubId?: InputMaybe<Scalars['ID']>;
};


export type MutationJoinWaitlistArgs = {
  email: Scalars['String'];
  locationId: Scalars['ID'];
  organizationId: Scalars['ID'];
};


export type MutationNoteCreateArgs = {
  notableId: Scalars['ID'];
  notableType: Scalars['String'];
  text: Scalars['String'];
};


export type MutationOrderCancelArgs = {
  cancelationNotes?: InputMaybe<Scalars['String']>;
  cancelationReason: OrderCancelationReason;
  id: Scalars['ID'];
  refund?: InputMaybe<Scalars['Boolean']>;
};


export type MutationOrderCreateArgs = {
  customerEmail?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<OrderKind>;
  subscriptionId?: InputMaybe<Scalars['ID']>;
  variantId: Scalars['ID'];
};


export type MutationOrderFulfillArgs = {
  chargeRestockFee?: InputMaybe<Scalars['Boolean']>;
  fulfillmentKind?: InputMaybe<OrderFulfillmentKind>;
  id: Scalars['ID'];
  maintenanceNotes?: InputMaybe<Scalars['String']>;
  serialNumber?: InputMaybe<Scalars['String']>;
};


export type MutationOrderOnHoldArgs = {
  id: Scalars['ID'];
};


export type MutationOrderOpenArgs = {
  id: Scalars['ID'];
};


export type MutationOrderReadyArgs = {
  assetId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  vehicleSerialNumber?: InputMaybe<Scalars['String']>;
};


export type MutationOrderRemoveAssetArgs = {
  id: Scalars['ID'];
};


export type MutationOrderVariantUpdateArgs = {
  id: Scalars['ID'];
  variantId: Scalars['ID'];
};


export type MutationOrdersExportArgs = {
  hubId?: InputMaybe<Scalars['ID']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<OrderSortColumn>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type MutationOrganizationCreateOrUpdateArgs = {
  attributes: OrganizationInput;
  organizationId?: InputMaybe<Scalars['ID']>;
};


export type MutationPaidInvoicesExportArgs = {
  endDate: Scalars['ISO8601Date'];
  hubId?: InputMaybe<Scalars['ID']>;
  organizationId: Scalars['ID'];
  startDate: Scalars['ISO8601Date'];
};


export type MutationPurchaseOrderAssetsExportArgs = {
  id: Scalars['ID'];
};


export type MutationPurchaseOrderCreateOrUpdateArgs = {
  attributes: PurchaseOrderInput;
  purchaseOrderId?: InputMaybe<Scalars['ID']>;
};


export type MutationPurchaseOrderDeleteArgs = {
  purchaseOrderId: Scalars['ID'];
};


export type MutationPurchaseOrderShipArgs = {
  purchaseOrderId: Scalars['ID'];
};


export type MutationPurchaseOrderSubmitArgs = {
  invoiceDocUrl?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  purchaseOrderId: Scalars['ID'];
  vendorSalesOrderId: Scalars['String'];
};


export type MutationRevenueByStateExportArgs = {
  endDate: Scalars['ISO8601Date'];
  startDate: Scalars['ISO8601Date'];
};


export type MutationSpokeCheckoutDataCreateArgs = {
  customerId: Scalars['ID'];
  sessionAttributes: SpokeCheckoutDataInput;
};


export type MutationSpokeCheckoutSessionCreateArgs = {
  sessionToken: Scalars['String'];
  termLength: TermLength;
};


export type MutationSpokeCreateOrUpdateArgs = {
  attributes: SpokeInput;
  spokeId?: InputMaybe<Scalars['ID']>;
};


export type MutationSpokeCustomerCreateArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locationId?: InputMaybe<Scalars['ID']>;
  organizationId: Scalars['ID'];
  phone: Scalars['String'];
  spokeId: Scalars['ID'];
};


export type MutationSpokeInvoiceTransitionStateArgs = {
  invoiceId: Scalars['ID'];
  state: SpokeInvoiceState;
};


export type MutationSpokeInvoiceUpdateArgs = {
  invoiceDocUrl: Scalars['Url'];
  invoiceId: Scalars['ID'];
  salesOrderNumber: Scalars['String'];
};


export type MutationSpokeUpdateBrandsArgs = {
  attributes: SpokeBrandsInput;
  spokeId: Scalars['ID'];
};


export type MutationSpokeUpdateLocationsArgs = {
  locationIds?: InputMaybe<Array<Scalars['ID']>>;
  spokeId: Scalars['ID'];
};


export type MutationSpokeUpdateVehicleCategoriesArgs = {
  spokeId: Scalars['ID'];
  vehicleCategoryIds: Array<Scalars['ID']>;
};


export type MutationSpokeVehicleCategoryCreateOrUpdateArgs = {
  attributes: SpokeVehicleCategoryInput;
  vehicleCategoryId?: InputMaybe<Scalars['ID']>;
};


export type MutationSubscriptionBuyoutArgs = {
  buyoutPriceCents: Scalars['Int'];
  soldAt?: InputMaybe<Scalars['String']>;
  subscriptionId: Scalars['ID'];
};


export type MutationSubscriptionCancelArgs = {
  attributes: SubscriptionCancelInput;
  subscriptionId: Scalars['ID'];
};


export type MutationSubscriptionCustomerOffboardArgs = {
  email: Scalars['String'];
  subscriptionId: Scalars['ID'];
};


export type MutationSubscriptionUpdateArgs = {
  attributes: SubscriptionUpdateInput;
  subscriptionId: Scalars['ID'];
};


export type MutationSubscriptionVariantUpdateArgs = {
  subscriptionId: Scalars['ID'];
  variantId: Scalars['ID'];
};


export type MutationSubscriptionsExportArgs = {
  hubId?: InputMaybe<Scalars['ID']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<SubscriptionSortColumn>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type MutationTheftReportCreateOrUpdateArgs = {
  attributes: TheftReportInput;
  theftReportId?: InputMaybe<Scalars['ID']>;
};


export type MutationVariantHubInventoryUpdateArgs = {
  variantsInput: Array<HubVariantInput>;
};


export type MutationVariantStatsExportArgs = {
  hubId?: InputMaybe<Scalars['ID']>;
  onlyNeeded?: InputMaybe<Scalars['Boolean']>;
  vendorIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationVehicleCreateOrUpdateArgs = {
  attributes: VehicleUpdateInput;
  vehicleId?: InputMaybe<Scalars['ID']>;
};


export type MutationVendorCreateOrUpdateArgs = {
  attributes: VendorInput;
  id?: InputMaybe<Scalars['ID']>;
};

export type Note = {
  createdAt: Scalars['ISO8601DateTime'];
  createdByEmail: Scalars['String'];
  id: Scalars['ID'];
  notableId: Scalars['ID'];
  notableType: Scalars['String'];
  text: Scalars['String'];
};

export type Order = {
  asset?: Maybe<Asset>;
  cancelationNotes?: Maybe<Scalars['String']>;
  cancelationReason?: Maybe<OrderCancelationReason>;
  closedAt?: Maybe<Scalars['ISO8601DateTime']>;
  comments: Array<Note>;
  createdAt: Scalars['ISO8601DateTime'];
  customer: Customer;
  directShip: Scalars['Boolean'];
  events: Array<Event>;
  fulfillmentKind?: Maybe<OrderFulfillmentKind>;
  hub: Hub;
  id: Scalars['ID'];
  kind: OrderKind;
  lease?: Maybe<Subscription>;
  organization: Organization;
  paymentDetails: PaymentDetails;
  plan: Plan;
  readiedAt?: Maybe<Scalars['ISO8601DateTime']>;
  state: OrderState;
  stateUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  stripeCheckoutInvoiceId?: Maybe<Scalars['String']>;
  subscription?: Maybe<Subscription>;
  updatedAt: Scalars['ISO8601DateTime'];
  variant: Variant;
  vehicleSerialNumber?: Maybe<Scalars['String']>;
};

export enum OrderCancelationReason {
  /** ordered_by_mistake order cancelation reason */
  OrderedByMistake = 'ORDERED_BY_MISTAKE',
  /** other order cancelation reason */
  Other = 'OTHER',
  /** purchased_elsewhere order cancelation reason */
  PurchasedElsewhere = 'PURCHASED_ELSEWHERE',
  /** taking_too_long order cancelation reason */
  TakingTooLong = 'TAKING_TOO_LONG',
  /** unknown order cancelation reason */
  Unknown = 'UNKNOWN',
  /** variant_change order cancelation reason */
  VariantChange = 'VARIANT_CHANGE'
}

/** The connection type for Order. */
export type OrderConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Order>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrderEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Order>;
};

export enum OrderFulfillmentKind {
  /** home_delivery order fulfillment kind */
  home_delivery = 'home_delivery',
  /** hub_pickup order fulfillment kind */
  hub_pickup = 'hub_pickup',
  /** office_delivery order fulfillment kind */
  office_delivery = 'office_delivery'
}

export enum OrderKind {
  /** subscription order kind */
  subscription = 'subscription',
  /** swap order kind */
  swap = 'swap'
}

export enum OrderSortColumn {
  ClosedAt = 'closedAt',
  CreatedAt = 'createdAt',
  email = 'email',
  kind = 'kind',
  LocationName = 'locationName',
  OrganizationName = 'organizationName',
  ReadiedAt = 'readiedAt',
  state = 'state',
  StateWeight = 'stateWeight',
  vehicle = 'vehicle'
}

export enum OrderState {
  /** canceled order state */
  canceled = 'canceled',
  /** fulfilled order state */
  fulfilled = 'fulfilled',
  /** on_hold order state */
  on_hold = 'on_hold',
  /** open order state */
  open = 'open',
  /** ready order state */
  ready = 'ready'
}

export type OrderValidationResult = {
  error?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  valid: Scalars['Boolean'];
};

export type Organization = {
  allowedDomains: Array<Scalars['String']>;
  allowsPersonalEmail: Scalars['Boolean'];
  authorizedEmailDomainsEnabled: Scalars['Boolean'];
  authorizedEmails: Scalars['String'];
  authorizedEmailsEnabled: Scalars['Boolean'];
  benefitsText?: Maybe<Scalars['String']>;
  benefitsTextEnabled: Scalars['Boolean'];
  benefitsUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  deliveryCopyHubIds?: Maybe<Array<Scalars['String']>>;
  discoverable: Scalars['Boolean'];
  faqs: Array<Faq>;
  historicalHubs: Array<Hub>;
  id: Scalars['ID'];
  /** @deprecated use `terms_and_conditions_url` */
  leasingTermsAndConditionsUrl?: Maybe<Scalars['String']>;
  locations: Array<Location>;
  logo?: Maybe<Image>;
  metadata?: Maybe<OrganizationMetadata>;
  name: Scalars['String'];
  organizationAdmins: Array<OrganizationAdmin>;
  organizationHubs: Array<OrganizationHub>;
  slug: Scalars['String'];
  spokeFlowEnabled: Scalars['Boolean'];
  subsidy: OrganizationSubsidy;
  termsAndConditionsUrl?: Maybe<Scalars['String']>;
  theftInsuranceEnabled: Scalars['Boolean'];
};

export type OrganizationAdmin = {
  email: Scalars['String'];
  id: Scalars['ID'];
  organization: Organization;
};

/** The connection type for OrganizationAdmin. */
export type OrganizationAdminConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrganizationAdminEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OrganizationAdmin>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OrganizationAdminEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<OrganizationAdmin>;
};

export type OrganizationAdminInput = {
  /** Admin User email */
  email: Scalars['String'];
  /** Admin User ID */
  id?: InputMaybe<Scalars['ID']>;
};

export type OrganizationHub = {
  hub: Hub;
  id: Scalars['ID'];
  organization: Organization;
  vehicleCategories: Array<VehicleCategory>;
};

export type OrganizationInput = {
  /** List of admin user emails */
  adminEmails?: InputMaybe<Array<Scalars['String']>>;
  /** The list of allowed domains users can login with this organization */
  allowedDomains?: InputMaybe<Array<Scalars['String']>>;
  /** If enabled, checkout will allow personal emails */
  allowsPersonalEmail?: InputMaybe<Scalars['Boolean']>;
  /** If enabled, checkout requires user email domain to match organization allowed domains list */
  authorizedEmailDomainsEnabled?: InputMaybe<Scalars['Boolean']>;
  /** If enabled, checkout requires user email to match organization authorized emails list */
  authorizedEmailsEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The benefits text shown on Portal */
  benefitsText?: InputMaybe<Scalars['String']>;
  /** If enabled, this organization will show the benefits text on Portal */
  benefitsTextEnabled?: InputMaybe<Scalars['Boolean']>;
  /** List of Hub IDs for displaying custom delivery copy on Portal */
  deliveryCopyHubIds?: InputMaybe<Array<Scalars['ID']>>;
  /** If enabled, this organization will appear in the portal organization selector */
  discoverable?: InputMaybe<Scalars['Boolean']>;
  /** List of FAQs shown on Portal */
  faqs?: InputMaybe<Array<FaqInput>>;
  /** The locations to associate the organization with */
  locations?: InputMaybe<Array<Scalars['ID']>>;
  /** The logo of the organization */
  logo?: InputMaybe<MediaInput>;
  /** The metadata of the organization */
  metadata?: InputMaybe<OrganizationMetadataInput>;
  /** The name of the organization */
  name?: InputMaybe<Scalars['String']>;
  /** The slug of the organization */
  slug?: InputMaybe<Scalars['String']>;
  /** If enabled, this organization will show the spoke user flow in the portal */
  spokeFlowEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The invoice credit amount to apply when subsidies are enabled */
  subsidy?: InputMaybe<OrganizationSubsidyInput>;
  /** The Terms and Conditions URL for the organization */
  termsAndConditionsUrl?: InputMaybe<Scalars['Url']>;
  /** If enabled, portal will show theft insurance language for this organization */
  theftInsuranceEnabled?: InputMaybe<Scalars['Boolean']>;
  /** List of the Organization's vehicle categories by hub */
  vehicleCategoriesByHub?: InputMaybe<Array<HubVehicleCategoriesInput>>;
};

export type OrganizationMetadata = {
  benefitsCardFunded?: Maybe<Scalars['ISO8601Date']>;
  customerSuccessManager?: Maybe<Scalars['String']>;
  expectedFirstOrder?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
  mainContact?: Maybe<Scalars['String']>;
  marketingLaunch?: Maybe<Scalars['ISO8601Date']>;
  marketingStrategyInfo?: Maybe<Scalars['String']>;
  organization: Organization;
  platform?: Maybe<Scalars['String']>;
  signoffApprovalByPartner: Scalars['Boolean'];
  signoffApprovalByPartnerDate?: Maybe<Scalars['ISO8601Date']>;
  totalPotentialEmployees?: Maybe<Scalars['Int']>;
};

export type OrganizationMetadataInput = {
  /** Date when the benefits card is funded */
  benefitsCardFunded?: InputMaybe<Scalars['ISO8601Date']>;
  /** Organization's customer success manager */
  customerSuccessManager?: InputMaybe<Scalars['String']>;
  /** Date when the marketing launch is live */
  expectedFirstOrder?: InputMaybe<Scalars['ISO8601Date']>;
  /** Organization's main contact */
  mainContact?: InputMaybe<Scalars['String']>;
  /** Platform the organization belongs to */
  marketingLaunch?: InputMaybe<Scalars['ISO8601Date']>;
  /** Marketing notes for the marketing strategy */
  marketingStrategyInfo?: InputMaybe<Scalars['String']>;
  /** Platform the organization belongs to */
  platform?: InputMaybe<Scalars['String']>;
  /** Whether the partner has approved signoff */
  signoffApprovalByPartner: Scalars['Boolean'];
  /** Date when the partner signoff is approved */
  signoffApprovalByPartnerDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** Organization's total potential employees */
  totalPotentialEmployees?: InputMaybe<Scalars['Int']>;
};

export type OrganizationSubsidy = {
  amount?: Maybe<Scalars['Float']>;
  cap?: Maybe<Scalars['Float']>;
  enabled: Scalars['Boolean'];
  process?: Maybe<OrganizationSubsidyProcess>;
};

export type OrganizationSubsidyInput = {
  /** Percent or dollar subsidy amount based on type */
  amount?: InputMaybe<Scalars['Float']>;
  /** Maximum amount for a percentage subsidy */
  cap?: InputMaybe<Scalars['Float']>;
  /** Whether the subsidy is enabled */
  enabled: Scalars['Boolean'];
  /** `fixed` or `percentage` */
  process?: InputMaybe<OrganizationSubsidyProcess>;
};

export enum OrganizationSubsidyProcess {
  /** fixed dollar subsidy */
  fixed = 'fixed',
  /** percentage subsidy */
  percentage = 'percentage'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  currentPage: Scalars['Int'];
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  totalItems: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PaidInvoice = {
  brand: Scalars['String'];
  color: Scalars['String'];
  email: Scalars['String'];
  firstMonth: Scalars['Boolean'];
  invoiceDate: Scalars['ISO8601Date'];
  leaseCancelDate?: Maybe<Scalars['ISO8601Date']>;
  leaseStartDate?: Maybe<Scalars['ISO8601Date']>;
  locationName: Scalars['String'];
  model: Scalars['String'];
  number: Scalars['String'];
  paidDate: Scalars['ISO8601Date'];
  size: Scalars['String'];
  state: Scalars['String'];
  swapped: Scalars['Boolean'];
  total: Scalars['Decimal'];
};

export type PaymentDetails = {
  planPriceCents?: Maybe<Scalars['Int']>;
  subsidy?: Maybe<OrganizationSubsidy>;
  subtotalCents?: Maybe<Scalars['Int']>;
  taxCents?: Maybe<Scalars['Int']>;
  taxRate?: Maybe<TaxRate>;
  totalCents?: Maybe<Scalars['Int']>;
};

export type Plan = {
  id: Scalars['ID'];
  postSubsidyPrice: Money;
  price: Money;
  vehicle: Vehicle;
};

export type PurchaseOrder = {
  additionalCostsCents: Scalars['Int'];
  assets: Array<Asset>;
  checkedInAt?: Maybe<Scalars['ISO8601DateTime']>;
  closedAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  directShip: Scalars['Boolean'];
  discountPercentage: Scalars['Float'];
  hub: Hub;
  id: Scalars['ID'];
  invoiceDoc?: Maybe<Media>;
  mediaAttachment?: Maybe<MediaAttachment>;
  notes?: Maybe<Scalars['String']>;
  purchaseOrderNumber: Scalars['Int'];
  salesOrderNumber?: Maybe<Scalars['String']>;
  shippedAt?: Maybe<Scalars['ISO8601DateTime']>;
  shippingCostsCents: Scalars['Int'];
  state: PurchaseOrderState;
  submittedAt?: Maybe<Scalars['ISO8601DateTime']>;
  trackingInfo?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  vendor: Vendor;
};

/** The connection type for PurchaseOrder. */
export type PurchaseOrderConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PurchaseOrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PurchaseOrder>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PurchaseOrderEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PurchaseOrder>;
};

export type PurchaseOrderInput = {
  /** The additional costs of the purchase order, in cents */
  additionalCostsCents?: InputMaybe<Scalars['Int']>;
  /** The assets of the purchase order */
  assets?: InputMaybe<Array<AssetInput>>;
  /** Whether the purchase order is direct shipped or not */
  directShip?: InputMaybe<Scalars['Boolean']>;
  /** The discount percentage of the purchase order */
  discountPercentage?: InputMaybe<Scalars['Float']>;
  /** The hub ID to associate the purchase order with */
  hubId: Scalars['ID'];
  /** The invoice doc URL of the purchase order */
  invoiceDocUrl?: InputMaybe<Scalars['String']>;
  /** The notes of the purchase order */
  notes?: InputMaybe<Scalars['String']>;
  /** The sales order number of the purchase order */
  salesOrderNumber?: InputMaybe<Scalars['String']>;
  /** The shipping costs of the purchase order, in cents */
  shippingCostsCents?: InputMaybe<Scalars['Int']>;
  /** The tracking info of the purchase order */
  trackingInfo?: InputMaybe<Scalars['String']>;
  /** The vendor ID to associate the purchase order with */
  vendorId: Scalars['ID'];
};

export enum PurchaseOrderSortColumn {
  AssetsCount = 'assetsCount',
  CheckedInAt = 'checkedInAt',
  ClosedAt = 'closedAt',
  CreatedAt = 'createdAt',
  HubName = 'hubName',
  PurchaseOrderNumber = 'purchaseOrderNumber',
  SalesOrderNumber = 'salesOrderNumber',
  state = 'state',
  VendorName = 'vendorName'
}

export enum PurchaseOrderState {
  /** check_in purchase order state */
  CheckIn = 'CHECK_IN',
  /** closed purchase order state */
  Closed = 'CLOSED',
  /** open purchase order state */
  Open = 'OPEN',
  /** shipped purchase order state */
  Shipped = 'SHIPPED',
  /** submitted purchase order state */
  Submitted = 'SUBMITTED'
}

export type PurchaseOrderTabs = {
  checkedInAssets: Array<AssetsForPoTab>;
  orderedAssets: Array<AssetsForPoTab>;
  overviewAssets: Array<AssetsForPoTab>;
  shippedAssets: Array<AssetsForPoTab>;
};

export type Query = {
  activeRidersMetrics: AnalyticsActiveRidersMetrics;
  asset: Asset;
  assetValidateCheckIn: ValidationResult;
  assets: AssetConnection;
  assetsAvailable?: Maybe<Array<Asset>>;
  brand: Brand;
  brands: Array<Brand>;
  checkoutSuccess: TransactionResult;
  currentAccessLevels: Array<UserAccessLevel>;
  customer: Customer;
  customers: CustomerConnection;
  featureEnabled: Scalars['Boolean'];
  featureEnabledForHub: Scalars['Boolean'];
  forestUser?: Maybe<User>;
  forestUsers: UserConnection;
  hub?: Maybe<Hub>;
  hubVariants: Array<HubVariant>;
  hubs: Array<Hub>;
  invoices: Array<Invoice>;
  leasePriceCalculator: LeasePrice;
  location?: Maybe<Location>;
  locations: Array<Location>;
  logHistories?: Maybe<Array<LogHistory>>;
  notes: Array<Note>;
  order?: Maybe<Order>;
  orders: OrderConnection;
  organization: Organization;
  organizationAdmin?: Maybe<OrganizationAdmin>;
  organizationAdmins: OrganizationAdminConnection;
  organizations: Array<Organization>;
  paidInvoicesReport: Array<PaidInvoice>;
  portalActivityMetrics: AnalyticsActivityMetrics;
  purchaseOrder: PurchaseOrder;
  purchaseOrderTabs: PurchaseOrderTabs;
  purchaseOrders: PurchaseOrderConnection;
  reportExport?: Maybe<ReportExport>;
  spoke?: Maybe<Spoke>;
  spokeCheckoutData?: Maybe<SpokeCheckoutData>;
  spokeInvoice?: Maybe<SpokeInvoice>;
  spokeInvoices?: Maybe<SpokeInvoiceConnection>;
  spokeLease?: Maybe<SpokeLease>;
  spokeVehicleCategories: Array<SpokeVehicleCategory>;
  spokeVehicleCategory: SpokeVehicleCategory;
  spokes: Array<Spoke>;
  subscription?: Maybe<Subscription>;
  subscriptions: SubscriptionConnection;
  validateCustomerActivationCode: CustomerValidationResult;
  validateEmail: ValidationResult;
  validatePhone: ValidationResult;
  validateVariantCanCheckOutAtHub: ValidationResult;
  variantStats: Array<VariantStat>;
  variantStatsByPurchaseOrder: Array<VariantStat>;
  vehicle?: Maybe<Vehicle>;
  vehicleFulfillmentTime?: Maybe<Scalars['String']>;
  vehicles?: Maybe<Array<Vehicle>>;
  vendor?: Maybe<Vendor>;
  vendorVehicles: Array<VendorVehicle>;
  vendors: VendorConnection;
};


export type QueryActiveRidersMetricsArgs = {
  hubId?: InputMaybe<Scalars['ID']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  projectUntil?: InputMaybe<Scalars['ISO8601Date']>;
};


export type QueryAssetArgs = {
  id: Scalars['ID'];
};


export type QueryAssetValidateCheckInArgs = {
  id: Scalars['ID'];
  serialNumber: Scalars['String'];
};


export type QueryAssetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  hubId?: InputMaybe<Scalars['ID']>;
  includeActiveSubscription?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<AssetSortColumn>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type QueryAssetsAvailableArgs = {
  orderId: Scalars['String'];
};


export type QueryBrandArgs = {
  id: Scalars['ID'];
};


export type QueryCheckoutSuccessArgs = {
  email: Scalars['String'];
};


export type QueryCustomerArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCustomersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  hubId?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<CustomerSortColumn>;
  sortDirection?: InputMaybe<SortDirection>;
  spokeOnly?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFeatureEnabledArgs = {
  flag: Scalars['String'];
};


export type QueryFeatureEnabledForHubArgs = {
  flag: Scalars['String'];
  hubId: Scalars['ID'];
};


export type QueryForestUserArgs = {
  id: Scalars['ID'];
};


export type QueryForestUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QueryHubArgs = {
  id: Scalars['ID'];
};


export type QueryHubVariantsArgs = {
  hubId?: InputMaybe<Scalars['ID']>;
  variantId?: InputMaybe<Scalars['ID']>;
};


export type QueryInvoicesArgs = {
  includeRefunded?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLeasePriceCalculatorArgs = {
  accessoriesRetailPriceCents: Scalars['Int'];
  employerSubsidyAmountCents: Scalars['Int'];
  termLength: TermLength;
  vehicleRetailPriceCents: Scalars['Int'];
};


export type QueryLocationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryLocationsArgs = {
  excludeWithoutHub?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLogHistoriesArgs = {
  loggableId: Scalars['ID'];
  loggableType: Scalars['String'];
};


export type QueryNotesArgs = {
  notableId: Scalars['ID'];
  notableType: Scalars['String'];
};


export type QueryOrderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  hubId?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<OrderSortColumn>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type QueryOrganizationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryOrganizationAdminArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationAdminsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryPaidInvoicesReportArgs = {
  endDate: Scalars['ISO8601DateTime'];
  startDate: Scalars['ISO8601DateTime'];
};


export type QueryPortalActivityMetricsArgs = {
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  hubId?: InputMaybe<Scalars['ID']>;
  locationId?: InputMaybe<Scalars['ID']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
};


export type QueryPurchaseOrderArgs = {
  id: Scalars['ID'];
};


export type QueryPurchaseOrderTabsArgs = {
  id: Scalars['ID'];
};


export type QueryPurchaseOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  hubId?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<PurchaseOrderSortColumn>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type QueryReportExportArgs = {
  id: Scalars['ID'];
};


export type QuerySpokeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySpokeCheckoutDataArgs = {
  sessionToken: Scalars['String'];
};


export type QuerySpokeInvoiceArgs = {
  customerId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySpokeInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sortColumn?: InputMaybe<SpokeInvoicesSortColumn>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type QuerySpokeLeaseArgs = {
  customerId?: InputMaybe<Scalars['ID']>;
  includeCanceled?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySpokeVehicleCategoryArgs = {
  id: Scalars['ID'];
};


export type QuerySpokesArgs = {
  locationId?: InputMaybe<Scalars['ID']>;
};


export type QuerySubscriptionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  hubId?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sortColumn?: InputMaybe<SubscriptionSortColumn>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type QueryValidateCustomerActivationCodeArgs = {
  activationCode: Scalars['String'];
};


export type QueryValidateEmailArgs = {
  email: Scalars['String'];
  organizationSlug?: InputMaybe<Scalars['String']>;
};


export type QueryValidatePhoneArgs = {
  phone: Scalars['String'];
};


export type QueryValidateVariantCanCheckOutAtHubArgs = {
  hubId: Scalars['ID'];
  variantId: Scalars['ID'];
};


export type QueryVariantStatsArgs = {
  hubId?: InputMaybe<Scalars['ID']>;
  onlyNeeded?: InputMaybe<Scalars['Boolean']>;
  vendorIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryVariantStatsByPurchaseOrderArgs = {
  onlyNeeded?: InputMaybe<Scalars['Boolean']>;
  purchaseOrderId: Scalars['ID'];
};


export type QueryVehicleArgs = {
  hubId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  organizationSlug?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryVehicleFulfillmentTimeArgs = {
  hubId: Scalars['ID'];
  vehicleId: Scalars['ID'];
};


export type QueryVehiclesArgs = {
  filter?: InputMaybe<VehiclesFilter>;
  hubId?: InputMaybe<Scalars['ID']>;
  locationSlug?: InputMaybe<Scalars['String']>;
  organizationSlug?: InputMaybe<Scalars['String']>;
};


export type QueryVendorArgs = {
  id: Scalars['ID'];
};


export type QueryVendorVehiclesArgs = {
  vendorId?: InputMaybe<Scalars['ID']>;
};


export type QueryVendorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ReportExport = {
  createdAt: Scalars['ISO8601DateTime'];
  downloadUrl?: Maybe<Scalars['String']>;
  failureReason?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  id: Scalars['ID'];
  reportType: Scalars['String'];
  requester: User;
  status: ExportReportStatus;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** A user-readable error */
export type ResourceError = {
  /** Resource identifier */
  id?: Maybe<Scalars['ID']>;
  /** A list of descriptive errors */
  messages: Array<Scalars['String']>;
};

export enum SortDirection {
  /** ascending */
  asc = 'asc',
  /** descending */
  desc = 'desc'
}

export type Spoke = {
  active: Scalars['Boolean'];
  address?: Maybe<Address>;
  admins: Array<User>;
  brands: Array<Brand>;
  businessHours: Scalars['String'];
  discountPercentage: Scalars['Float'];
  email?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  googleMapsUrl: Scalars['Url'];
  id: Scalars['ID'];
  locations: Array<Location>;
  logo?: Maybe<Image>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  salesTaxEnabled: Scalars['Boolean'];
  salesTaxPercentage: Scalars['Float'];
  stripeConnectedAccountId?: Maybe<Scalars['String']>;
  vehicleCategories: Array<SpokeVehicleCategory>;
  websiteUrl: Scalars['Url'];
};

export type SpokeBrandsInput = {
  /** The brand IDs to associate the spoke with */
  brandIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type SpokeCheckoutData = {
  accessoriesPriceCents: Scalars['Int'];
  brand: Brand;
  color: Scalars['String'];
  customer: Customer;
  description?: Maybe<Scalars['String']>;
  model: Scalars['String'];
  serialNumber: Scalars['String'];
  size: Scalars['String'];
  spoke: Spoke;
  thirtySixMonthPrices: LeasePrice;
  token: Scalars['String'];
  twelveMonthPrices: LeasePrice;
  twentyFourMonthPrices: LeasePrice;
  vehicleCategory: VehicleCategory;
  vehicleRetailPriceCents: Scalars['Int'];
};

export type SpokeCheckoutDataInput = {
  /** Selected Accessories price, in cents */
  accessoriesPriceCents: Scalars['Int'];
  /** Selected vehicle brand id */
  brandId: Scalars['ID'];
  /** Selected vehicle color */
  color: Scalars['String'];
  /** Selected vehicle description */
  description: Scalars['String'];
  /** Selected vehicle model */
  model: Scalars['String'];
  /** Selected vehicle serial number */
  serialNumber: Scalars['String'];
  /** Selected vehicle size */
  size: Scalars['String'];
  /** Selected spoke vehicle category */
  vehicleCategory: VehicleCategory;
  /** Selected vehicle price, in cents */
  vehicleRetailPriceCents: Scalars['Int'];
};

export type SpokeCustomer = {
  email: Scalars['String'];
  firebaseId: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  organization: Organization;
  phone: Scalars['String'];
  spoke: Spoke;
};

export type SpokeCustomerCreateResult = {
  error?: Maybe<Scalars['String']>;
  spokeCustomer?: Maybe<SpokeCustomer>;
  valid: Scalars['Boolean'];
};

export type SpokeInput = {
  /** Whether the spoke is active */
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<AddressInput>;
  /** List of allowed admins */
  admins?: InputMaybe<Array<UserInput>>;
  /** The brand IDs to associate the spoke with */
  brandIds?: InputMaybe<Array<Scalars['ID']>>;
  /** The business hours of the spoke */
  businessHours?: InputMaybe<Scalars['String']>;
  /** The agreed sales discount percentage of the Spoke */
  discountPercentage: Scalars['Float'];
  /** The contact email of the spoke */
  email?: InputMaybe<Scalars['String']>;
  /** The Google Maps URL for the spoke location */
  googleMapsUrl?: InputMaybe<Scalars['Url']>;
  /** The location IDs to associate the spoke with */
  locationIds?: InputMaybe<Array<Scalars['ID']>>;
  /** The logo of the spoke */
  logo?: InputMaybe<MediaInput>;
  /** The name of the spoke */
  name?: InputMaybe<Scalars['String']>;
  /** The contact phone of the spoke */
  phone?: InputMaybe<Scalars['String']>;
  /** Whether to collect sales taxes from Spoke */
  salesTaxEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The sales tax percentage of the Spoke */
  salesTaxPercentage: Scalars['Float'];
  /**  Spokes's Stripe connected account ID */
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  /** The spoke vehicle category IDs to associate the spoke with */
  vehicleCategoryIds?: InputMaybe<Array<Scalars['ID']>>;
  /** The website URL of the spoke */
  websiteUrl?: InputMaybe<Scalars['String']>;
};

export type SpokeInvoice = {
  accessoriesRetailPriceCents: Scalars['Int'];
  canceledReason?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  customer: Customer;
  discountCents: Scalars['Int'];
  discountPercentage: Scalars['Float'];
  /** The date after which the associated lease term length ends, or the date the lease was canceled */
  endDate?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
  /** @deprecated use `media_attachment` instead */
  invoiceDocUrl?: Maybe<Scalars['Url']>;
  kind: SpokeInvoiceKind;
  lease?: Maybe<SpokeLease>;
  mediaAttachment?: Maybe<MediaAttachment>;
  notes: Array<Note>;
  salesOrderNumber?: Maybe<Scalars['String']>;
  salesTaxCents: Scalars['Int'];
  salesTaxPercentage: Scalars['Float'];
  spoke: Spoke;
  state: SpokeInvoiceState;
  stateUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  totalCents: Scalars['Int'];
  vehicleBrand: Brand;
  vehicleCategory: VehicleCategory;
  vehicleColor: Scalars['String'];
  vehicleDescription: Scalars['String'];
  vehicleDisplayName: Scalars['String'];
  vehicleModel: Scalars['String'];
  vehicleRetailPriceCents: Scalars['Int'];
  vehicleSerialNumber: Scalars['String'];
  vehicleSize: Scalars['String'];
};

/** The connection type for SpokeInvoice. */
export type SpokeInvoiceConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SpokeInvoiceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SpokeInvoice>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SpokeInvoiceEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<SpokeInvoice>;
};

export enum SpokeInvoiceKind {
  /** lease invoice_kind */
  Lease = 'LEASE',
  /** maintenance invoice_kind */
  Maintenance = 'MAINTENANCE'
}

export enum SpokeInvoiceState {
  /** approved invoice_state */
  Approved = 'APPROVED',
  /** needs_review invoice_state */
  NeedsReview = 'NEEDS_REVIEW',
  /** paid invoice_state */
  Paid = 'PAID',
  /** pending invoice_state */
  Pending = 'PENDING'
}

export enum SpokeInvoicesSortColumn {
  CreatedAt = 'createdAt',
  CustomerName = 'customerName',
  kind = 'kind',
  OrganizationName = 'organizationName',
  SalesOrderNumber = 'salesOrderNumber',
  SpokeName = 'spokeName',
  state = 'state',
  TotalCents = 'totalCents'
}

export type SpokeLease = {
  buyoutDate?: Maybe<Scalars['ISO8601Date']>;
  buyoutPriceCents?: Maybe<Scalars['Int']>;
  canceledAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  currentPeriodEnd?: Maybe<Scalars['ISO8601Date']>;
  currentPeriodStart?: Maybe<Scalars['ISO8601Date']>;
  customer: Customer;
  id: Scalars['ID'];
  monthlyTotalPriceCents: Scalars['Int'];
  organization: Organization;
  paymentDetails: PaymentDetails;
  state: SpokeLeaseState;
  stripeSubscriptionId: Scalars['String'];
  termLengthMonths: Scalars['Int'];
  vehicle: SpokeVehicle;
};

export enum SpokeLeaseState {
  /** active spoke_lease_state */
  Active = 'ACTIVE',
  /** canceled spoke_lease_state */
  Canceled = 'CANCELED'
}

export type SpokeVehicle = {
  brand: Brand;
  color: Scalars['String'];
  description: Scalars['String'];
  displayName: Scalars['String'];
  model: Scalars['String'];
  serialNumber: Scalars['String'];
  size: Scalars['String'];
};

export type SpokeVehicleCategory = {
  icon?: Maybe<Image>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SpokeVehicleCategoryInput = {
  /** The icon of the spoke vehicle category */
  icon?: InputMaybe<MediaInput>;
  /** The name of the spoke vehicle category */
  name?: InputMaybe<Scalars['String']>;
};

export enum StandardizedSize {
  /** 'L' variant standardized size */
  L = 'L',
  /** 'M' variant standardized size */
  M = 'M',
  /** 'ONE SIZE' variant standardized size */
  OneSize = 'ONE_SIZE',
  /** 'S' variant standardized size */
  S = 'S',
  /** 'XL' variant standardized size */
  Xl = 'XL',
  /** 'XS' variant standardized size */
  Xs = 'XS'
}

export type Subscription = {
  asset?: Maybe<Asset>;
  cancelationNotes?: Maybe<Scalars['String']>;
  cancelationReason?: Maybe<SubscriptionCancelationReason>;
  comments: Array<Note>;
  createdAt: Scalars['ISO8601DateTime'];
  currentPeriodEnd?: Maybe<Scalars['ISO8601DateTime']>;
  currentPeriodStart?: Maybe<Scalars['ISO8601DateTime']>;
  customer: Customer;
  /** @deprecated use `ended_at` instead */
  endDate?: Maybe<Scalars['ISO8601Date']>;
  endedAt?: Maybe<Scalars['ISO8601DateTime']>;
  events?: Maybe<Array<Event>>;
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  orders: Array<Order>;
  organization: Organization;
  paymentDetails: PaymentDetails;
  plan: Plan;
  /** waive if more than 12 months have passed since start */
  restockFeeWaived: Scalars['Boolean'];
  /** @deprecated use `started_at` instead */
  startDate?: Maybe<Scalars['ISO8601Date']>;
  startedAt?: Maybe<Scalars['ISO8601DateTime']>;
  state: SubscriptionState;
  stateUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  variant: Variant;
};

/** The input fields used to cancel a Subscription. */
export type SubscriptionCancelInput = {
  /** Admin notes for the subscription cancelation */
  cancelationNotes?: InputMaybe<Scalars['String']>;
  /** Cancelation reason of the subscription */
  cancelationReason: SubscriptionCancelationReason;
  /** Whether to charge restock fee via Stripe */
  chargeRestockFee?: InputMaybe<Scalars['Boolean']>;
  /** The description of the maintenance if any */
  maintenanceNotes?: InputMaybe<Scalars['String']>;
  /** Serial number of the asset linked to the subscription */
  serialNumber?: InputMaybe<Scalars['String']>;
};

export enum SubscriptionCancelationReason {
  /** benefit_change subscription cancelation reason */
  BenefitChange = 'BENEFIT_CHANGE',
  /** buyout subscription cancelation reason */
  Buyout = 'BUYOUT',
  /** internship_ended subscription cancelation reason */
  InternshipEnded = 'INTERNSHIP_ENDED',
  /** leaving_employer subscription cancelation reason */
  LeavingEmployer = 'LEAVING_EMPLOYER',
  /** moving_location subscription cancelation reason */
  MovingLocation = 'MOVING_LOCATION',
  /** not_enough_power subscription cancelation reason */
  NotEnoughPower = 'NOT_ENOUGH_POWER',
  /** not_enough_range subscription cancelation reason */
  NotEnoughRange = 'NOT_ENOUGH_RANGE',
  /** not_using_vehicle subscription cancelation reason */
  NotUsingVehicle = 'NOT_USING_VEHICLE',
  /** other subscription cancelation reason */
  Other = 'OTHER',
  /** stolen subscription cancelation reason */
  Stolen = 'STOLEN',
  /**
   * terminated subscription cancelation reason
   * @deprecated not used anymore
   */
  Terminated = 'TERMINATED',
  /** unknown subscription cancelation reason */
  Unknown = 'UNKNOWN',
  /** unsatisfied_with_program_terms subscription cancelation reason */
  UnsatisfiedWithProgramTerms = 'UNSATISFIED_WITH_PROGRAM_TERMS',
  /** vehicle_is_wrong_size subscription cancelation reason */
  VehicleIsWrongSize = 'VEHICLE_IS_WRONG_SIZE',
  /**
   * vehicle_not_a_fit subscription cancelation reason
   * @deprecated not used anymore
   */
  VehicleNotAFit = 'VEHICLE_NOT_A_FIT'
}

/** The connection type for Subscription. */
export type SubscriptionConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SubscriptionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Subscription>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SubscriptionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Subscription>;
};

export enum SubscriptionSortColumn {
  CreatedAt = 'createdAt',
  email = 'email',
  LocationName = 'locationName',
  OrganizationName = 'organizationName',
  /** @deprecated use `startedAt` instead */
  StartDate = 'startDate',
  StartedAt = 'startedAt',
  state = 'state',
  VehicleName = 'vehicleName'
}

export enum SubscriptionState {
  /** active subscription state */
  active = 'active',
  /** canceled subscription state */
  canceled = 'canceled'
}

/** The input fields used to update a Subscription. */
export type SubscriptionUpdateInput = {
  /** True if the subscription needs review by an admin */
  needsReview?: InputMaybe<Scalars['Boolean']>;
  /** Admin notes for the subscription */
  notes?: InputMaybe<Scalars['String']>;
};

export type TaxRate = {
  /** The name of the tax rate */
  name?: Maybe<Scalars['String']>;
  /** The percentage of the tax rate */
  percentage?: Maybe<Scalars['Float']>;
};

export enum TermLength {
  ThirtySix = 'THIRTY_SIX',
  Twelve = 'TWELVE',
  TwentyFour = 'TWENTY_FOUR'
}

export type TheftReport = {
  asset: Asset;
  createdAt: Scalars['ISO8601DateTime'];
  deductibleAmountCents?: Maybe<Scalars['Int']>;
  documents: Array<Media>;
  id: Scalars['ID'];
  insuranceClaimId?: Maybe<Scalars['String']>;
  insuranceProceedsDate?: Maybe<Scalars['ISO8601Date']>;
  proceedsCents?: Maybe<Scalars['Int']>;
  stripeDeductiblePaymentId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type TheftReportInput = {
  /** The ID of the asset to create the theft report for */
  assetId: Scalars['ID'];
  /** The deductible amount, in cents */
  deductibleAmountCents: Scalars['Int'];
  /** Documents to attach to the theft report */
  documents?: InputMaybe<Array<MediaInput>>;
  /** The ID of the insurance claim */
  insuranceClaimId: Scalars['String'];
  /** The insurance proceeds date */
  insuranceProceedsDate?: InputMaybe<Scalars['ISO8601Date']>;
  /** The proceeds amount, in cents */
  proceedsCents: Scalars['Int'];
  /** The ID of the stripe payment */
  stripeDeductiblePaymentId: Scalars['String'];
};

export type TransactionResult = {
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type User = {
  accessLevels: Array<UserAccessLevel>;
  disabled: Scalars['Boolean'];
  email: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  hub?: Maybe<Hub>;
  id: Scalars['ID'];
};

export enum UserAccessLevel {
  /** customer access level */
  Customer = 'CUSTOMER',
  /** forest_admin access level */
  ForestAdmin = 'FOREST_ADMIN',
  /** organization_admin access level */
  OrganizationAdmin = 'ORGANIZATION_ADMIN',
  /** spoke_admin access level */
  SpokeAdmin = 'SPOKE_ADMIN'
}

/** The connection type for User. */
export type UserConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

export type UserInput = {
  /** User Access Levels */
  accessLevels?: InputMaybe<Array<UserAccessLevel>>;
  /** User Email */
  email?: InputMaybe<Scalars['String']>;
  /** User id. passing null will create a new user */
  id?: InputMaybe<Scalars['ID']>;
};

export type ValidationResult = {
  error?: Maybe<Scalars['String']>;
  valid: Scalars['Boolean'];
};

export type Variant = {
  brand: Scalars['String'];
  color: Scalars['String'];
  colorCode: Scalars['ColorCode'];
  description: Scalars['String'];
  headerImage?: Maybe<Image>;
  hubVariants: Array<HubVariant>;
  id: Scalars['ID'];
  image: Image;
  inStock: Scalars['Boolean'];
  maxHeight?: Maybe<Scalars['String']>;
  minHeight?: Maybe<Scalars['String']>;
  model: Scalars['String'];
  /** The size and heights of the variant */
  size: Scalars['String'];
  standardizedSize: StandardizedSize;
  vehicle: Vehicle;
};

export type VariantInput = {
  /** Variant color */
  color?: InputMaybe<Scalars['String']>;
  /** Variant color hex code */
  colorCode?: InputMaybe<Scalars['ColorCode']>;
  /** Image to use for the riders page vehicles card */
  headerImage?: InputMaybe<MediaInput>;
  /** Variant id. passing null will create a new variant */
  id?: InputMaybe<Scalars['ID']>;
  /** Image to use for the variant */
  image?: InputMaybe<MediaInput>;
  /** Variant max height */
  maxHeight?: InputMaybe<Scalars['String']>;
  /** Variant min height */
  minHeight?: InputMaybe<Scalars['String']>;
  /** Variant standardized size */
  standardizedSize?: InputMaybe<StandardizedSize>;
};

export type VariantStat = {
  /** Returns null when cannnot be determined */
  allowBackorders?: Maybe<Scalars['Boolean']>;
  available: Scalars['Int'];
  brand: Scalars['String'];
  color: Scalars['String'];
  decommissioned: Scalars['Int'];
  description: Scalars['String'];
  handoff: Scalars['Int'];
  maintenanceHold: Scalars['Int'];
  model: Scalars['String'];
  needed: Scalars['Int'];
  openOrders: Scalars['Int'];
  openPo: Scalars['Int'];
  received: Scalars['Int'];
  returned: Scalars['Int'];
  size: Scalars['String'];
  sold: Scalars['Int'];
  stolen: Scalars['Int'];
  subscription: Scalars['Int'];
  testRides: Scalars['Int'];
  total: Scalars['Int'];
  variantId: Scalars['ID'];
  /** The vehicle wholesale price reference, in cents */
  wholesalePriceCents: Scalars['Int'];
};

export type Vehicle = {
  allowBuyout: Scalars['Boolean'];
  brand: Scalars['String'];
  category: VehicleCategory;
  chargeTime?: Maybe<Scalars['String']>;
  chargeTimeHours?: Maybe<FloatRange>;
  /** If enabled, vehicle will display as a core model */
  coreModel: Scalars['Boolean'];
  details?: Maybe<Scalars['String']>;
  highlight1?: Maybe<Scalars['String']>;
  highlight2?: Maybe<Scalars['String']>;
  highlight3?: Maybe<Scalars['String']>;
  highlights?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  images?: Maybe<Array<Image>>;
  inStock: Scalars['Boolean'];
  maxLoad?: Maybe<Scalars['String']>;
  maxLoadLbs?: Maybe<Scalars['Int']>;
  model: Scalars['String'];
  msrpCents?: Maybe<Scalars['Int']>;
  plan?: Maybe<Plan>;
  primaryVariant?: Maybe<Variant>;
  range?: Maybe<Scalars['String']>;
  rangeMiles?: Maybe<Scalars['Int']>;
  recommendationAttribution?: Maybe<Scalars['String']>;
  recommendationQuote?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  stripeProductId: Scalars['String'];
  timeToFulfillWeeks: Scalars['String'];
  topSpeed?: Maybe<Scalars['String']>;
  topSpeedMph?: Maybe<Scalars['Int']>;
  variants: Array<Variant>;
  weight?: Maybe<Scalars['String']>;
  weightLbs?: Maybe<Scalars['Int']>;
  wholesalePriceCents?: Maybe<Scalars['Int']>;
};

export enum VehicleCategory {
  /** ebike */
  Ebike = 'EBIKE',
  /** escooter */
  Escooter = 'ESCOOTER',
  /** pedal_bike */
  PedalBike = 'PEDAL_BIKE',
  /** test */
  Test = 'TEST'
}

export type VehicleUpdateInput = {
  /** If the vehicle is allowed to be bought out */
  allowBuyout?: InputMaybe<Scalars['Boolean']>;
  /** list of allowed hub ids */
  allowedHubIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Vehicle brand */
  brand?: InputMaybe<Scalars['String']>;
  /** Vehicle category */
  category?: InputMaybe<VehicleCategory>;
  /** charge time hours range */
  chargeTimeHours?: InputMaybe<FloatRangeInput>;
  /** If enabled, vehicle will display as a core model */
  coreModel?: InputMaybe<Scalars['Boolean']>;
  /** details */
  details?: InputMaybe<Scalars['String']>;
  /** highlight1 */
  highlight1?: InputMaybe<Scalars['String']>;
  /** highlight2 */
  highlight2?: InputMaybe<Scalars['String']>;
  /** highlight3 */
  highlight3?: InputMaybe<Scalars['String']>;
  /** list of images */
  images?: InputMaybe<Array<MediaInput>>;
  /** max load in lbs */
  maxLoadLbs?: InputMaybe<Scalars['Int']>;
  /** Vehicle model */
  model?: InputMaybe<Scalars['String']>;
  /** msrp price in cents */
  msrpCents?: InputMaybe<Scalars['Int']>;
  /** Vehicle lease price */
  price?: InputMaybe<MoneyInput>;
  /** the id for the primary variant */
  primaryVariantId?: InputMaybe<Scalars['ID']>;
  /** range in miles */
  rangeMiles?: InputMaybe<Scalars['Int']>;
  /** recommendation attribution */
  recommendationAttribution?: InputMaybe<Scalars['String']>;
  /** recommendation quote */
  recommendationQuote?: InputMaybe<Scalars['String']>;
  /** short description */
  shortDescription?: InputMaybe<Scalars['String']>;
  /** top speed in mph */
  topSpeedMph?: InputMaybe<Scalars['Int']>;
  /** list of variants */
  variants?: InputMaybe<Array<VariantInput>>;
  /** weight in lbs */
  weightLbs?: InputMaybe<Scalars['Int']>;
  /** wholesale price in cents */
  wholesalePriceCents?: InputMaybe<Scalars['Int']>;
};

export enum VehiclesFilter {
  All = 'ALL',
  Available = 'AVAILABLE',
  /** @deprecated will be removed in a future release */
  InStock = 'IN_STOCK'
}

export type Vendor = {
  id: Scalars['ID'];
  maxTimeToFulfill: Scalars['Int'];
  minTimeToFulfill: Scalars['Int'];
  name: Scalars['String'];
  vehicles: Array<Vehicle>;
};

/** The connection type for Vendor. */
export type VendorConnection = {
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<VendorEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Vendor>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type VendorEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Vendor>;
};

export type VendorInput = {
  /** Maximum time to fulfill order */
  maxTimeToFulfill: Scalars['Int'];
  /** Minimum time to fulfill order */
  minTimeToFulfill: Scalars['Int'];
  /** Vendor Name */
  name: Scalars['String'];
  /** Vendor Vehicle IDs */
  vehicleIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type VendorVehicle = {
  description: Scalars['String'];
  /** whether the vehicle is enabled for the vendor */
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  /** whether the vehicle is associated to the vendor */
  linked: Scalars['Boolean'];
};

export type GetActiveRidersMetricsQueryVariables = Exact<{
  hubId?: InputMaybe<Scalars['ID']>;
  organizationId?: InputMaybe<Scalars['ID']>;
}>;


export type GetActiveRidersMetricsQuery = { activeRidersMetrics: { currentStats: { totalCo2OffsetKg: number, totalMiles: number, treesPlantedEquivalency: number, carTripsAvoided: number, details: Array<{ date: any, ebikeCount: number, escooterCount: number, pedalBikeCount: number, totalCount: number }> }, projectedStats?: { totalCo2OffsetKg: number, totalMiles: number, treesPlantedEquivalency: number, carTripsAvoided: number, details: Array<{ date: any, ebikeCount: number, escooterCount: number, pedalBikeCount: number, totalCount: number }> } | null } };

export type PaidInvoicesReportQueryVariables = Exact<{
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
}>;


export type PaidInvoicesReportQuery = { paidInvoicesReport: Array<{ number: string, email: string, locationName: string, brand: string, model: string, size: string, color: string, state: string, total: any, invoiceDate: any, paidDate: any, leaseStartDate?: any | null, leaseCancelDate?: any | null, firstMonth: boolean, swapped: boolean }> };

export type PortalActivityMetricsQueryVariables = Exact<{
  hubId?: InputMaybe<Scalars['ID']>;
  locationId?: InputMaybe<Scalars['ID']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
}>;


export type PortalActivityMetricsQuery = { portalActivityMetrics: { currentStats: { date: any, ordersCount: number, sessionsCount: number, uniqueVisitorsCount: number, details: Array<{ date: any, ordersCount: number, sessionsCount: number, uniqueVisitorsCount: number }> }, previousStats: { date: any, ordersCount: number, sessionsCount: number, uniqueVisitorsCount: number } } };

export type InvoiceDocUrlCreateMutationVariables = Exact<{
  prefix: Scalars['String'];
  mimeType: Scalars['String'];
  fileSize: Scalars['BigInt'];
}>;


export type InvoiceDocUrlCreateMutation = { fileUploadUrlCreate?: { httpMethod: string, uploadUrl: any, publicUrl: any, existingFile: boolean } | null };

export type PaidInvoicesExportMutationVariables = Exact<{
  hubId?: InputMaybe<Scalars['ID']>;
  organizationId: Scalars['ID'];
  startDate: Scalars['ISO8601Date'];
  endDate: Scalars['ISO8601Date'];
}>;


export type PaidInvoicesExportMutation = { paidInvoicesExport?: { id: string, status: ExportReportStatus } | null };

export type SpokeCheckoutDataCreateMutationVariables = Exact<{
  customerId: Scalars['ID'];
  sessionAttributes: SpokeCheckoutDataInput;
}>;


export type SpokeCheckoutDataCreateMutation = { spokeCheckoutDataCreate?: { checkoutUrl: any } | null };

export type SpokeInvoiceUpdateMutationVariables = Exact<{
  invoiceId: Scalars['ID'];
  salesOrderNumber: Scalars['String'];
  invoiceDocUrl: Scalars['Url'];
}>;


export type SpokeInvoiceUpdateMutation = { spokeInvoiceUpdate?: { id: string, invoiceDocUrl?: any | null, salesOrderNumber?: string | null } | null };

export type GetCurrentAccessLevelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentAccessLevelsQuery = { currentAccessLevels: Array<UserAccessLevel> };

export type GetOrganizationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationQuery = { organization: { id: string, name: string, logo?: { url: any } | null, locations: Array<{ name: string, id: string }>, historicalHubs: Array<{ id: string, name: string }> } };

export type GetSpokeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSpokeQuery = { spoke?: { id: string, name: string, email?: string | null, fullAddress?: string | null, phone?: string | null, businessHours: string, googleMapsUrl: any, websiteUrl: any, discountPercentage: number, salesTaxPercentage: number, address?: { city: string, countryCode: string, line1: string, line2?: string | null, postalCode: string, stateCode: string } | null, logo?: { id: string, url: any } | null, locations: Array<{ name: string }>, vehicleCategories: Array<{ id: string, name: string, icon?: { id: string, url: any } | null }>, brands: Array<{ id: string, name: string, logo?: { id: string, url: any } | null }> } | null };

export type SpokeInvoiceQueryVariables = Exact<{
  customerId?: InputMaybe<Scalars['ID']>;
  invoiceId?: InputMaybe<Scalars['ID']>;
}>;


export type SpokeInvoiceQuery = { spokeInvoice?: { id: string, salesOrderNumber?: string | null, state: SpokeInvoiceState, vehicleModel: string, vehicleSize: string, vehicleColor: string, vehicleDescription: string, vehicleSerialNumber: string, vehicleRetailPriceCents: number, accessoriesRetailPriceCents: number, discountPercentage: number, discountCents: number, salesTaxPercentage: number, salesTaxCents: number, totalCents: number, stateUpdatedAt?: any | null, lease?: { termLengthMonths: number, monthlyTotalPriceCents: number, buyoutDate?: any | null, buyoutPriceCents?: number | null } | null, spoke: { name: string, salesTaxEnabled: boolean, email?: string | null, phone?: string | null }, customer: { firstName?: string | null, lastName?: string | null }, mediaAttachment?: { uploadedBy?: string | null, media: { url: any } } | null } | null };

export type SpokeInvoiceForTableFragment = { salesOrderNumber?: string | null, id: string, kind: SpokeInvoiceKind, state: SpokeInvoiceState, createdAt: any, stateUpdatedAt?: any | null, totalCents: number, mediaAttachment?: { uploadedBy?: string | null, media: { url: any } } | null, customer: { firstName?: string | null, lastName?: string | null } };

export type SpokeInvoicesQueryVariables = Exact<{
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sortColumn?: InputMaybe<SpokeInvoicesSortColumn>;
  sortDirection?: InputMaybe<SortDirection>;
}>;


export type SpokeInvoicesQuery = { spokeInvoices?: { invoices?: Array<{ salesOrderNumber?: string | null, id: string, kind: SpokeInvoiceKind, state: SpokeInvoiceState, createdAt: any, stateUpdatedAt?: any | null, totalCents: number, mediaAttachment?: { uploadedBy?: string | null, media: { url: any } } | null, customer: { firstName?: string | null, lastName?: string | null } } | null> | null, pageInfo: { currentPage: number, totalItems: number, totalPages: number } } | null };

export type ValidateCustomerActivationCodeQueryVariables = Exact<{
  activationCode: Scalars['String'];
}>;


export type ValidateCustomerActivationCodeQuery = { validateCustomerActivationCode: { valid: boolean, error?: string | null, customer?: { id: string, email?: string | null, phone?: string | null, firstName?: string | null, lastName?: string | null, activationCode: string, organization: { name: string } } | null } };

export const SpokeInvoiceForTableFragmentDoc = gql`
    fragment spokeInvoiceForTable on SpokeInvoice {
  salesOrderNumber
  id
  mediaAttachment {
    media {
      url
    }
    uploadedBy
  }
  kind
  state
  customer {
    firstName
    lastName
  }
  createdAt
  stateUpdatedAt
  totalCents
}
    `;
export const GetActiveRidersMetricsDocument = gql`
    query GetActiveRidersMetrics($hubId: ID, $organizationId: ID) {
  activeRidersMetrics(hubId: $hubId, organizationId: $organizationId) {
    currentStats {
      details {
        date
        ebikeCount
        escooterCount
        pedalBikeCount
        totalCount
      }
      totalCo2OffsetKg
      totalMiles
      treesPlantedEquivalency
      carTripsAvoided
    }
    projectedStats {
      details {
        date
        ebikeCount
        escooterCount
        pedalBikeCount
        totalCount
      }
      totalCo2OffsetKg
      totalMiles
      treesPlantedEquivalency
      carTripsAvoided
    }
  }
}
    `;

/**
 * __useGetActiveRidersMetricsQuery__
 *
 * To run a query within a React component, call `useGetActiveRidersMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveRidersMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveRidersMetricsQuery({
 *   variables: {
 *      hubId: // value for 'hubId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetActiveRidersMetricsQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveRidersMetricsQuery, GetActiveRidersMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveRidersMetricsQuery, GetActiveRidersMetricsQueryVariables>(GetActiveRidersMetricsDocument, options);
      }
export function useGetActiveRidersMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveRidersMetricsQuery, GetActiveRidersMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveRidersMetricsQuery, GetActiveRidersMetricsQueryVariables>(GetActiveRidersMetricsDocument, options);
        }
export type GetActiveRidersMetricsQueryHookResult = ReturnType<typeof useGetActiveRidersMetricsQuery>;
export type GetActiveRidersMetricsLazyQueryHookResult = ReturnType<typeof useGetActiveRidersMetricsLazyQuery>;
export type GetActiveRidersMetricsQueryResult = Apollo.QueryResult<GetActiveRidersMetricsQuery, GetActiveRidersMetricsQueryVariables>;
export const PaidInvoicesReportDocument = gql`
    query PaidInvoicesReport($startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!) {
  paidInvoicesReport(startDate: $startDate, endDate: $endDate) {
    number
    email
    locationName
    brand
    model
    size
    color
    state
    total
    invoiceDate
    paidDate
    leaseStartDate
    leaseCancelDate
    firstMonth
    swapped
  }
}
    `;

/**
 * __usePaidInvoicesReportQuery__
 *
 * To run a query within a React component, call `usePaidInvoicesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaidInvoicesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaidInvoicesReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function usePaidInvoicesReportQuery(baseOptions: Apollo.QueryHookOptions<PaidInvoicesReportQuery, PaidInvoicesReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaidInvoicesReportQuery, PaidInvoicesReportQueryVariables>(PaidInvoicesReportDocument, options);
      }
export function usePaidInvoicesReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaidInvoicesReportQuery, PaidInvoicesReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaidInvoicesReportQuery, PaidInvoicesReportQueryVariables>(PaidInvoicesReportDocument, options);
        }
export type PaidInvoicesReportQueryHookResult = ReturnType<typeof usePaidInvoicesReportQuery>;
export type PaidInvoicesReportLazyQueryHookResult = ReturnType<typeof usePaidInvoicesReportLazyQuery>;
export type PaidInvoicesReportQueryResult = Apollo.QueryResult<PaidInvoicesReportQuery, PaidInvoicesReportQueryVariables>;
export const PortalActivityMetricsDocument = gql`
    query PortalActivityMetrics($hubId: ID, $locationId: ID, $organizationId: ID, $startDate: ISO8601Date, $endDate: ISO8601Date) {
  portalActivityMetrics(
    hubId: $hubId
    locationId: $locationId
    organizationId: $organizationId
    startDate: $startDate
    endDate: $endDate
  ) {
    currentStats {
      date
      details {
        date
        ordersCount
        sessionsCount
        uniqueVisitorsCount
      }
      ordersCount
      sessionsCount
      uniqueVisitorsCount
    }
    previousStats {
      date
      ordersCount
      sessionsCount
      uniqueVisitorsCount
    }
  }
}
    `;

/**
 * __usePortalActivityMetricsQuery__
 *
 * To run a query within a React component, call `usePortalActivityMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortalActivityMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortalActivityMetricsQuery({
 *   variables: {
 *      hubId: // value for 'hubId'
 *      locationId: // value for 'locationId'
 *      organizationId: // value for 'organizationId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function usePortalActivityMetricsQuery(baseOptions?: Apollo.QueryHookOptions<PortalActivityMetricsQuery, PortalActivityMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PortalActivityMetricsQuery, PortalActivityMetricsQueryVariables>(PortalActivityMetricsDocument, options);
      }
export function usePortalActivityMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PortalActivityMetricsQuery, PortalActivityMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PortalActivityMetricsQuery, PortalActivityMetricsQueryVariables>(PortalActivityMetricsDocument, options);
        }
export type PortalActivityMetricsQueryHookResult = ReturnType<typeof usePortalActivityMetricsQuery>;
export type PortalActivityMetricsLazyQueryHookResult = ReturnType<typeof usePortalActivityMetricsLazyQuery>;
export type PortalActivityMetricsQueryResult = Apollo.QueryResult<PortalActivityMetricsQuery, PortalActivityMetricsQueryVariables>;
export const InvoiceDocUrlCreateDocument = gql`
    mutation InvoiceDocUrlCreate($prefix: String!, $mimeType: String!, $fileSize: BigInt!) {
  fileUploadUrlCreate(prefix: $prefix, mimeType: $mimeType, fileSize: $fileSize) {
    httpMethod
    uploadUrl
    publicUrl
    existingFile
  }
}
    `;
export type InvoiceDocUrlCreateMutationFn = Apollo.MutationFunction<InvoiceDocUrlCreateMutation, InvoiceDocUrlCreateMutationVariables>;

/**
 * __useInvoiceDocUrlCreateMutation__
 *
 * To run a mutation, you first call `useInvoiceDocUrlCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceDocUrlCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceDocUrlCreateMutation, { data, loading, error }] = useInvoiceDocUrlCreateMutation({
 *   variables: {
 *      prefix: // value for 'prefix'
 *      mimeType: // value for 'mimeType'
 *      fileSize: // value for 'fileSize'
 *   },
 * });
 */
export function useInvoiceDocUrlCreateMutation(baseOptions?: Apollo.MutationHookOptions<InvoiceDocUrlCreateMutation, InvoiceDocUrlCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvoiceDocUrlCreateMutation, InvoiceDocUrlCreateMutationVariables>(InvoiceDocUrlCreateDocument, options);
      }
export type InvoiceDocUrlCreateMutationHookResult = ReturnType<typeof useInvoiceDocUrlCreateMutation>;
export type InvoiceDocUrlCreateMutationResult = Apollo.MutationResult<InvoiceDocUrlCreateMutation>;
export type InvoiceDocUrlCreateMutationOptions = Apollo.BaseMutationOptions<InvoiceDocUrlCreateMutation, InvoiceDocUrlCreateMutationVariables>;
export const PaidInvoicesExportDocument = gql`
    mutation PaidInvoicesExport($hubId: ID, $organizationId: ID!, $startDate: ISO8601Date!, $endDate: ISO8601Date!) {
  paidInvoicesExport(
    hubId: $hubId
    organizationId: $organizationId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    status
  }
}
    `;
export type PaidInvoicesExportMutationFn = Apollo.MutationFunction<PaidInvoicesExportMutation, PaidInvoicesExportMutationVariables>;

/**
 * __usePaidInvoicesExportMutation__
 *
 * To run a mutation, you first call `usePaidInvoicesExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaidInvoicesExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paidInvoicesExportMutation, { data, loading, error }] = usePaidInvoicesExportMutation({
 *   variables: {
 *      hubId: // value for 'hubId'
 *      organizationId: // value for 'organizationId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function usePaidInvoicesExportMutation(baseOptions?: Apollo.MutationHookOptions<PaidInvoicesExportMutation, PaidInvoicesExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PaidInvoicesExportMutation, PaidInvoicesExportMutationVariables>(PaidInvoicesExportDocument, options);
      }
export type PaidInvoicesExportMutationHookResult = ReturnType<typeof usePaidInvoicesExportMutation>;
export type PaidInvoicesExportMutationResult = Apollo.MutationResult<PaidInvoicesExportMutation>;
export type PaidInvoicesExportMutationOptions = Apollo.BaseMutationOptions<PaidInvoicesExportMutation, PaidInvoicesExportMutationVariables>;
export const SpokeCheckoutDataCreateDocument = gql`
    mutation SpokeCheckoutDataCreate($customerId: ID!, $sessionAttributes: SpokeCheckoutDataInput!) {
  spokeCheckoutDataCreate(
    customerId: $customerId
    sessionAttributes: $sessionAttributes
  ) {
    checkoutUrl
  }
}
    `;
export type SpokeCheckoutDataCreateMutationFn = Apollo.MutationFunction<SpokeCheckoutDataCreateMutation, SpokeCheckoutDataCreateMutationVariables>;

/**
 * __useSpokeCheckoutDataCreateMutation__
 *
 * To run a mutation, you first call `useSpokeCheckoutDataCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpokeCheckoutDataCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spokeCheckoutDataCreateMutation, { data, loading, error }] = useSpokeCheckoutDataCreateMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      sessionAttributes: // value for 'sessionAttributes'
 *   },
 * });
 */
export function useSpokeCheckoutDataCreateMutation(baseOptions?: Apollo.MutationHookOptions<SpokeCheckoutDataCreateMutation, SpokeCheckoutDataCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SpokeCheckoutDataCreateMutation, SpokeCheckoutDataCreateMutationVariables>(SpokeCheckoutDataCreateDocument, options);
      }
export type SpokeCheckoutDataCreateMutationHookResult = ReturnType<typeof useSpokeCheckoutDataCreateMutation>;
export type SpokeCheckoutDataCreateMutationResult = Apollo.MutationResult<SpokeCheckoutDataCreateMutation>;
export type SpokeCheckoutDataCreateMutationOptions = Apollo.BaseMutationOptions<SpokeCheckoutDataCreateMutation, SpokeCheckoutDataCreateMutationVariables>;
export const SpokeInvoiceUpdateDocument = gql`
    mutation SpokeInvoiceUpdate($invoiceId: ID!, $salesOrderNumber: String!, $invoiceDocUrl: Url!) {
  spokeInvoiceUpdate(
    invoiceId: $invoiceId
    salesOrderNumber: $salesOrderNumber
    invoiceDocUrl: $invoiceDocUrl
  ) {
    id
    invoiceDocUrl
    salesOrderNumber
  }
}
    `;
export type SpokeInvoiceUpdateMutationFn = Apollo.MutationFunction<SpokeInvoiceUpdateMutation, SpokeInvoiceUpdateMutationVariables>;

/**
 * __useSpokeInvoiceUpdateMutation__
 *
 * To run a mutation, you first call `useSpokeInvoiceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpokeInvoiceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spokeInvoiceUpdateMutation, { data, loading, error }] = useSpokeInvoiceUpdateMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      salesOrderNumber: // value for 'salesOrderNumber'
 *      invoiceDocUrl: // value for 'invoiceDocUrl'
 *   },
 * });
 */
export function useSpokeInvoiceUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SpokeInvoiceUpdateMutation, SpokeInvoiceUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SpokeInvoiceUpdateMutation, SpokeInvoiceUpdateMutationVariables>(SpokeInvoiceUpdateDocument, options);
      }
export type SpokeInvoiceUpdateMutationHookResult = ReturnType<typeof useSpokeInvoiceUpdateMutation>;
export type SpokeInvoiceUpdateMutationResult = Apollo.MutationResult<SpokeInvoiceUpdateMutation>;
export type SpokeInvoiceUpdateMutationOptions = Apollo.BaseMutationOptions<SpokeInvoiceUpdateMutation, SpokeInvoiceUpdateMutationVariables>;
export const GetCurrentAccessLevelsDocument = gql`
    query GetCurrentAccessLevels {
  currentAccessLevels
}
    `;

/**
 * __useGetCurrentAccessLevelsQuery__
 *
 * To run a query within a React component, call `useGetCurrentAccessLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentAccessLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentAccessLevelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentAccessLevelsQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentAccessLevelsQuery, GetCurrentAccessLevelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentAccessLevelsQuery, GetCurrentAccessLevelsQueryVariables>(GetCurrentAccessLevelsDocument, options);
      }
export function useGetCurrentAccessLevelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentAccessLevelsQuery, GetCurrentAccessLevelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentAccessLevelsQuery, GetCurrentAccessLevelsQueryVariables>(GetCurrentAccessLevelsDocument, options);
        }
export type GetCurrentAccessLevelsQueryHookResult = ReturnType<typeof useGetCurrentAccessLevelsQuery>;
export type GetCurrentAccessLevelsLazyQueryHookResult = ReturnType<typeof useGetCurrentAccessLevelsLazyQuery>;
export type GetCurrentAccessLevelsQueryResult = Apollo.QueryResult<GetCurrentAccessLevelsQuery, GetCurrentAccessLevelsQueryVariables>;
export const GetOrganizationDocument = gql`
    query GetOrganization {
  organization {
    id
    name
    logo {
      url
    }
    locations {
      name
      id
    }
    historicalHubs {
      id
      name
    }
  }
}
    `;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
      }
export function useGetOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
        }
export type GetOrganizationQueryHookResult = ReturnType<typeof useGetOrganizationQuery>;
export type GetOrganizationLazyQueryHookResult = ReturnType<typeof useGetOrganizationLazyQuery>;
export type GetOrganizationQueryResult = Apollo.QueryResult<GetOrganizationQuery, GetOrganizationQueryVariables>;
export const GetSpokeDocument = gql`
    query GetSpoke {
  spoke {
    id
    name
    email
    address {
      city
      countryCode
      line1
      line2
      postalCode
      stateCode
    }
    fullAddress
    phone
    businessHours
    googleMapsUrl
    websiteUrl
    discountPercentage
    salesTaxPercentage
    logo {
      id
      url
    }
    locations {
      name
    }
    vehicleCategories {
      id
      icon {
        id
        url
      }
      name
    }
    brands {
      id
      logo {
        id
        url
      }
      name
    }
  }
}
    `;

/**
 * __useGetSpokeQuery__
 *
 * To run a query within a React component, call `useGetSpokeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpokeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpokeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSpokeQuery(baseOptions?: Apollo.QueryHookOptions<GetSpokeQuery, GetSpokeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpokeQuery, GetSpokeQueryVariables>(GetSpokeDocument, options);
      }
export function useGetSpokeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpokeQuery, GetSpokeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpokeQuery, GetSpokeQueryVariables>(GetSpokeDocument, options);
        }
export type GetSpokeQueryHookResult = ReturnType<typeof useGetSpokeQuery>;
export type GetSpokeLazyQueryHookResult = ReturnType<typeof useGetSpokeLazyQuery>;
export type GetSpokeQueryResult = Apollo.QueryResult<GetSpokeQuery, GetSpokeQueryVariables>;
export const SpokeInvoiceDocument = gql`
    query SpokeInvoice($customerId: ID, $invoiceId: ID) {
  spokeInvoice(customerId: $customerId, id: $invoiceId) {
    id
    salesOrderNumber
    state
    vehicleModel
    vehicleSize
    vehicleColor
    vehicleDescription
    vehicleSerialNumber
    vehicleRetailPriceCents
    accessoriesRetailPriceCents
    discountPercentage
    discountCents
    salesTaxPercentage
    salesTaxCents
    totalCents
    lease {
      termLengthMonths
      monthlyTotalPriceCents
      buyoutDate
      buyoutPriceCents
    }
    stateUpdatedAt
    spoke {
      name
      salesTaxEnabled
      email
      phone
    }
    customer {
      firstName
      lastName
    }
    mediaAttachment {
      media {
        url
      }
      uploadedBy
    }
  }
}
    `;

/**
 * __useSpokeInvoiceQuery__
 *
 * To run a query within a React component, call `useSpokeInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpokeInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpokeInvoiceQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useSpokeInvoiceQuery(baseOptions?: Apollo.QueryHookOptions<SpokeInvoiceQuery, SpokeInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpokeInvoiceQuery, SpokeInvoiceQueryVariables>(SpokeInvoiceDocument, options);
      }
export function useSpokeInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpokeInvoiceQuery, SpokeInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpokeInvoiceQuery, SpokeInvoiceQueryVariables>(SpokeInvoiceDocument, options);
        }
export type SpokeInvoiceQueryHookResult = ReturnType<typeof useSpokeInvoiceQuery>;
export type SpokeInvoiceLazyQueryHookResult = ReturnType<typeof useSpokeInvoiceLazyQuery>;
export type SpokeInvoiceQueryResult = Apollo.QueryResult<SpokeInvoiceQuery, SpokeInvoiceQueryVariables>;
export const SpokeInvoicesDocument = gql`
    query SpokeInvoices($currentPage: Int, $pageSize: Int, $sortColumn: SpokeInvoicesSortColumn, $sortDirection: SortDirection) {
  spokeInvoices(
    currentPage: $currentPage
    pageSize: $pageSize
    sortColumn: $sortColumn
    sortDirection: $sortDirection
  ) {
    invoices: nodes {
      ...spokeInvoiceForTable
    }
    pageInfo {
      currentPage
      totalItems
      totalPages
    }
  }
}
    ${SpokeInvoiceForTableFragmentDoc}`;

/**
 * __useSpokeInvoicesQuery__
 *
 * To run a query within a React component, call `useSpokeInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpokeInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpokeInvoicesQuery({
 *   variables: {
 *      currentPage: // value for 'currentPage'
 *      pageSize: // value for 'pageSize'
 *      sortColumn: // value for 'sortColumn'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useSpokeInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<SpokeInvoicesQuery, SpokeInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpokeInvoicesQuery, SpokeInvoicesQueryVariables>(SpokeInvoicesDocument, options);
      }
export function useSpokeInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpokeInvoicesQuery, SpokeInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpokeInvoicesQuery, SpokeInvoicesQueryVariables>(SpokeInvoicesDocument, options);
        }
export type SpokeInvoicesQueryHookResult = ReturnType<typeof useSpokeInvoicesQuery>;
export type SpokeInvoicesLazyQueryHookResult = ReturnType<typeof useSpokeInvoicesLazyQuery>;
export type SpokeInvoicesQueryResult = Apollo.QueryResult<SpokeInvoicesQuery, SpokeInvoicesQueryVariables>;
export const ValidateCustomerActivationCodeDocument = gql`
    query validateCustomerActivationCode($activationCode: String!) {
  validateCustomerActivationCode(activationCode: $activationCode) {
    valid
    error
    customer {
      id
      email
      phone
      firstName
      lastName
      activationCode
      organization {
        name
      }
    }
  }
}
    `;

/**
 * __useValidateCustomerActivationCodeQuery__
 *
 * To run a query within a React component, call `useValidateCustomerActivationCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateCustomerActivationCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateCustomerActivationCodeQuery({
 *   variables: {
 *      activationCode: // value for 'activationCode'
 *   },
 * });
 */
export function useValidateCustomerActivationCodeQuery(baseOptions: Apollo.QueryHookOptions<ValidateCustomerActivationCodeQuery, ValidateCustomerActivationCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateCustomerActivationCodeQuery, ValidateCustomerActivationCodeQueryVariables>(ValidateCustomerActivationCodeDocument, options);
      }
export function useValidateCustomerActivationCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateCustomerActivationCodeQuery, ValidateCustomerActivationCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateCustomerActivationCodeQuery, ValidateCustomerActivationCodeQueryVariables>(ValidateCustomerActivationCodeDocument, options);
        }
export type ValidateCustomerActivationCodeQueryHookResult = ReturnType<typeof useValidateCustomerActivationCodeQuery>;
export type ValidateCustomerActivationCodeLazyQueryHookResult = ReturnType<typeof useValidateCustomerActivationCodeLazyQuery>;
export type ValidateCustomerActivationCodeQueryResult = Apollo.QueryResult<ValidateCustomerActivationCodeQuery, ValidateCustomerActivationCodeQueryVariables>;